import styles from "./Wrapper.module.scss"

function ModalsWrapper({
  child,
  isActive,
  width,
}: {
  child: JSX.Element
  isActive: boolean
  width?: string | number
}) {
  return (
    <div
      className={`${styles.modal} ${isActive ? styles["modal--active"] : ""}`}
    >
      <div className={styles.modal__content} style={{ width: width + "px" }}>
        {child}
      </div>
    </div>
  )
}

export default ModalsWrapper
