import { useState } from "react"
import Button from "@/components/common/Button"
import useBreakpoint from "use-breakpoint"
import { BREAKPOINTS } from "@/app/static"
import {
  ModalBuyNft,
  ModalBuyToken,
  ModalSubscription,
  ModalSubscriptionRenew,
  ModalTopUpBalance,
  ModalGotAWin,
  ModalNewPrizeDraw,
  ModalProjectNFT,
  ModalTokensDraw,
  ModalNFTDraw,
  ModalNewRelease,
  ModalYourSub,
  ModalEndSubscription,
  ModalWithdrawal,
  ModalBalance,
  ModalOTP,
  ModalSignIn,
} from "@/components/elements/Modals"

function Modals() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  const [isBuyNft, setBuyNft] = useState(false)
  const [isBuyToken, setBuyToken] = useState(false)

  const [isRenew, setRenew] = useState(false)
  const [isSubscribe, setSubscribe] = useState(false)

  const [isTopUp, setTopup] = useState(false)

  const [isNewPrice, setNewPrize] = useState(false)
  const [isWin, setWin] = useState(false)

  const [newDraw, setNewDraw] = useState(false)
  const [newNftDraw, setNewNftDraw] = useState(false)
  const [newRelease, setNewRelease] = useState(false)
  const [projectNft, setProjectNft] = useState(false)

  const [tokenWithdraw, setTokenWithdraw] = useState(false)
  const [endSubscription, setEndSubscription] = useState(false)
  const [yourSub, setYourSub] = useState(false)
  const [topBalance, setTopBalance] = useState(false)
  const [login, setLogin] = useState(false)
  const [OTP, setOTP] = useState(false)

  function actionModal() {
    console.log("ACTION")
  }
  function closeModal() {
    // setActiveModal(false)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <div>
        <Button text="BUY NFT" onClick={() => setBuyNft(!isBuyNft)} />

        <Button text="BUY TOKEN" onClick={() => setBuyToken(!isBuyToken)} />
        <ModalBuyNft
          isActive={isBuyNft}
          close={() => setBuyNft(false)}
          action={actionModal}
        />
        <ModalBuyToken
          isActive={isBuyToken}
          close={() => setBuyToken(false)}
          action={actionModal}
        />
      </div>

      <div>
        <Button
          text="Purchase subscription"
          onClick={() => setRenew(!isRenew)}
        />
        <Button
          text="Renewing the subscription"
          onClick={() => setSubscribe(!isSubscribe)}
        />

        <ModalSubscription
          isActive={isRenew}
          close={() => setRenew(false)}
          action={actionModal}
        />
        <ModalSubscriptionRenew
          isActive={isSubscribe}
          close={() => setSubscribe(false)}
          action={actionModal}
        />
      </div>

      <div>
        <Button text="Top up the balance" onClick={() => setTopup(!isTopUp)} />
        <ModalTopUpBalance
          isActive={isTopUp}
          action={actionModal}
          close={() => setTopup(false)}
        />
      </div>

      <div>
        <Button
          text="A new prize draw"
          onClick={() => setNewPrize(!isNewPrice)}
        />
        <Button text="Got a win" onClick={() => setWin(!isWin)} />

        <ModalGotAWin
          isActive={isWin}
          close={() => setWin(false)}
          action={actionModal}
        />
        <ModalNewPrizeDraw
          isActive={isNewPrice}
          close={() => setNewPrize(false)}
          action={actionModal}
        />
      </div>

      <div>
        <Button text="A new tokens draw" onClick={() => setNewDraw(!newDraw)} />
        <Button
          text="A new NFT draw"
          onClick={() => setNewNftDraw(!newNftDraw)}
        />

        <Button
          text="A new kind of NFT has been released"
          onClick={() => setNewRelease(!newRelease)}
        />
        <Button
          text="Got a project NFT"
          onClick={() => setProjectNft(!projectNft)}
        />

        <ModalProjectNFT
          isActive={projectNft}
          close={() => setProjectNft(false)}
          action={actionModal}
        />

        <ModalTokensDraw
          isActive={newDraw}
          close={() => setNewDraw(false)}
          action={actionModal}
        />

        <ModalNFTDraw
          isActive={newNftDraw}
          close={() => setNewNftDraw(false)}
          action={actionModal}
        />

        <ModalNewRelease
          isActive={newRelease}
          close={() => setNewRelease(false)}
          action={actionModal}
        />
      </div>

      <div>
        <br />
        <Button
          text="Token withdrawal"
          onClick={() => setTokenWithdraw(!tokenWithdraw)}
        />


        <br />
        <Button
          text="There are 10 days"
          onClick={() => setEndSubscription(!endSubscription)}
        />


        <br />
        <Button text="Your subscription" onClick={() => setYourSub(!yourSub)} />

        <br />
        <Button text="Top balance" onClick={() => setTopBalance(!topBalance)} />

        <br />
        <Button text="Login" onClick={() => setLogin(!login)} />
        <ModalSignIn
          isActive={login}
          action={actionModal}
          close={() => setLogin(false)}
        />

        <br />
        <Button text="OTP Code" onClick={() => setOTP(!OTP)} />
        <ModalOTP
          isActive={OTP}
          action={actionModal}
          close={() => setOTP(false)}
        />

        <ModalYourSub isActive={yourSub} action={actionModal} />

        <ModalEndSubscription isActive={endSubscription} action={actionModal} />

        <ModalWithdrawal isActive={tokenWithdraw} action={actionModal} />

        <ModalBalance isActive={topBalance} action={actionModal} />

      </div>
    </div>
  )
}

export default Modals
