import { getIn } from 'immutable';



const root = 'state';


export const getState = (state) =>
  getIn(state, [root], {});
export const getNextDrawAt = (state) =>
  getIn(state, [root, 'nextDrawAt'], 0);
export const getParticipantsForStart = (state) =>
  getIn(state, [root, 'participantsForStart'], 0);

