//import { useEffect } from 'react';
//import { useSelector, useDispatch } from 'react-redux';
import AppRoutes from '@/router';
import ScrollToTopOnMount from '@/components/app/ScrollToTopOnMount';


import {
  useWeb3Modal, useWeb3ModalAccount, useWeb3ModalState, useWeb3ModalEvents, useWeb3ModalProvider,
} from '@web3modal/ethers/react';
import { BrowserProvider, formatUnits, formatEther } from 'ethers';



export default function App() {
  //const dispatch = useDispatch();

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open, selectedNetworkId } = useWeb3ModalState();
  const events = useWeb3ModalEvents();
  //console.log('events', events)
  const { walletProvider, walletProviderType } = useWeb3ModalProvider();
  //console.log('walletProviderType', walletProviderType, walletProvider)


  return (
    <>
      <ScrollToTopOnMount />
      <AppRoutes />
    </>
  );
}
