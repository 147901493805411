import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const setTimeMs = (data) => ({
  type: AT.SET_TIME_MS,
  payload: data,
});
