import styles from "./Tokenomics.module.scss"
import { useTranslation } from 'react-i18next';
import Button from "@/components/common/Button"

import ImageTokenomics from "@/assets/img/tokenomics/tokenomics.png";
import ImageTokenomicsX2 from "@/assets/img/tokenomics/tokenomics@2x.png";
import ImageTokenomicsGradient from "@/assets/img/tokenomics/gradient.png";
import ImageTokenomicsGradientX2 from "@/assets/img/tokenomics/gradient@2x.png";

import { wmcTokenLinkBuyPancakeSwap } from "@/config";



export default function Tokenomics() {
  const { t } = useTranslation('Tokenomics');

  const onAction = (e: any) => {
    e.preventDefault();
    window.open(wmcTokenLinkBuyPancakeSwap);
  }

  return (
    <section id='wmc-token' className={styles.tokenomics}>
      <div className={styles["tokenomics__bg-top"]}></div>
      <div className={styles["tokenomics__bg-bottom"]}></div>
      <div className={styles.tokenomics__content}>
        <div className={`${styles.tokenomics__relative} container-xxl`}>
          <div className="row align-items-center justify-content-md-center">
            <div className="col-12 col-sm-8 order-2 order-sm-1 col-md-5 col-xl-5">
              <h2 className={styles.tokenomics__title}>{t('Tokenomics')}</h2>
              <p className={styles.tokenomics__desc}>
                {t('Message')}
              </p>
              <div className={styles.tokenomics__opacity}>
                <span>{t('Promotion')}</span>
              </div>
              <div>
                <Button
                  className={styles.tokenomics__button}
                  onClick={onAction}
                  text={t('ActionButton')}
                />
              </div>
            </div>
            <div
              className={`col-12 order-sm-2 order-1 col-sm-5 col-xl-4 offset-xl-1 ${styles.tokenomics__imgblock}`}
            >
              <div className={styles.tokenomics__imgbox}>
                <img
                  src={ImageTokenomics}
                  srcSet={`${ImageTokenomicsX2} 2x`}
                  alt="Tokenomics image"
                  width="354"
                  height="472"
                  className={styles.tokenomics__img}
                />
                <img
                  src={ImageTokenomicsGradient}
                  srcSet={`${ImageTokenomicsGradientX2} 2x`}
                  alt="Tokenomics image"
                  width="919"
                  height="731"
                  className={styles.tokenomics__shadow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
