import styles from "./Clocks.module.scss"
import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import * as S from '@/store/selectors';
import * as A from '@/store/actions';



const initState = { hours: '00', minutes: '00', seconds: '00', };

export default function Clocks() {
  const timeNowMs = useSelector((state) => S.clock.getTimeMs(state));
  const nextChallengeTimeMs = useSelector((state) => new Date(S.state.getNextDrawAt(state)).getTime());
  //const nextChallengeTimeMs = new Date('2023-11-23T04:00:00.000Z').getTime();
  //const nextChallengeTimeMs = useSelector((state) => new Date(S.clock.getTimeMs(state)).getTime());

  const [time, setTime] = useState(initState);
  useEffect(() => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (nextChallengeTimeMs > timeNowMs) {
      const delta = nextChallengeTimeMs - timeNowMs;
      hours = Math.floor(delta / (60 * 60 * 1000)) % 24;
      minutes = Math.floor(delta / (60 * 1000)) % 60;
      seconds = Math.floor(delta / (1000)) % 60;
    }

    setTime({
      hours: (hours).toString().padStart(2, '0'),
      minutes: (minutes).toString().padStart(2, '0'),
      seconds: (seconds).toString().padStart(2, '0'),
    });
  }, [timeNowMs]);

  return (
    <div className={styles.clocks}>
      <div className={styles.clocks__item}>{time.hours}h</div>
      <div className={styles.clocks__dot}>:</div>
      <div className={styles.clocks__item}>{time.minutes}m</div>
      <div className={styles.clocks__dot}>:</div>
      <div className={styles.clocks__item}>{time.seconds}s</div>
    </div>
  );
}
