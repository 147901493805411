import styles from "./Roadmap.module.scss"
import { useTranslation } from 'react-i18next';
import Hint from "@/components/elements/Hint"

import ImageRoadmapSpace from "@/assets/img/roadmap/space.png";
import ImageRoadmapSpaceX2 from "@/assets/img/roadmap/space@2x.png";
import ImageRoadmapGradient from "@/assets/img/roadmap/gradient.png";
import ImageRoadmapGradientX2 from "@/assets/img/roadmap/gradient@2x.png";
import ImageRoadmapGradient2 from "@/assets/img/roadmap/gradient_2.png";
import ImageRoadmapGradient2X2 from "@/assets/img/roadmap/gradient_2@2x.png";



function RoadmapItem(props: {
  text: string
  variant?: "default" | "success"
  title: string
  className?: string
}) {
  return (
    <div className={`${styles.info} ${props.className}`}>
      <div
        className={`${styles.info__title} ${styles[`info__title--${props.variant}`]}`}
      >
        {props.title}
      </div>
      <p className={styles.info__desc}>{props.text}</p>
    </div>
  )
}

export default function Roadmap() {
  const { t } = useTranslation('Roadmap');

  return (
    <section id="roadmap" className={styles.roadmap}>
      <img
        src={ImageRoadmapGradient}
        srcSet={`${ImageRoadmapGradientX2} 2x`}
        alt="Roadmap image"
        className={styles.roadmap__shadow}
      />
      <img
        src={ImageRoadmapGradient2}
        srcSet={`${ImageRoadmapGradient2X2} 2x`}
        alt="Roadmap image"
        className={styles["roadmap__shadow--two"]}
      />

      <br /><br /><br /><br />
      <div className="container-md">
        <div className={styles.roadmap__head}>
          <h2 className={styles.roadmap__title}>{t('Roadmap')}</h2>
          <p className={styles.roadmap__desc}>
            {t('Title')}
          </p>
        </div>
        <div className={styles.roadmap__content}>
          <div className={styles.roadmap__plates}>
            <img
              src={ImageRoadmapSpace}
              srcSet={`${ImageRoadmapSpaceX2} 2x`}
              alt="Roadmap image"
              className={styles.roadmap__bg}
            />
            <RoadmapItem
              text={t('ListingToken')}
              title={t('With 10,000 participants')}
              className={styles["info--first"]}
            //variant="success"
            />
            <RoadmapItem
              text={t('Launching a DAO')}
              title={t('With 100,000 participants')}
              className={styles["info--second"]}
            //variant="success"
            />
            <RoadmapItem
              text={t('Reaching 1,000,000 participants')}
              title={t('In process')}
              className={styles["info--third"]}
            //variant="success"
            />
          </div>
        </div>
        <Hint />
        <br /><br />
      </div>
    </section>
  );
}
