import { useState, useRef, useEffect } from "react"
import styles from "./Accordeon.module.scss"
import Arrow from "@/assets/img/arrow-bottom.svg?react"

function Accordeon({
  title,
  description,
}: {
  title: string
  description: string
}) {
  const [isActive, setActive] = useState(false)
  const text = useRef(null)

  return (
    <div
      className={`${styles.accordeon} ${
        isActive ? `${styles[`accordeon--active`]}` : ""
      }`}
      onClick={() => setActive(!isActive)}
    >
      <div className={styles.accordeon__wrapper}>
        <div className={styles.accordeon__top}>
          <h3 className={styles.accordeon__title}>{title}</h3>
          <div className={styles.accordeon__arrow}>
            <Arrow />
          </div>
        </div>
        <p
          ref={text}
          className={styles.accordeon__text}
          style={{ height: isActive ? text.current?.scrollHeight + "px" : 0 }}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

export default Accordeon
