import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { HashRouter as Router } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';

import { ModalProvider } from 'react-simple-modal-provider';
import { modalsL1, modalsL2, } from '@/modals';

import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from '@/store';
import ApiContext from '@/api/api-context';


import * as S from '@/store/selectors';
import * as A from '@/store/actions';
import pause from '@/utils/pause';


import { Manager, } from 'socket.io-client';
import { wsDomain, } from '@/config';



async function setupTimer(
  setTimerIntervalId: (intervalId: any) => void,
  dispatch: any
) {
  const delta = 1000 - Date.now() % 1000;
  await pause(delta);
  const intervalId = setInterval(() => {
    dispatch(A.clock.setTimeMs(Date.now()));
  }, 1000);
  setTimerIntervalId(intervalId);
}



const manager = new Manager(wsDomain, {
  reconnectionDelayMax: 10000,
  /*query: {
    'my-key': 'my-value',
  },*/
});

function setupEventsSocket(dispatch: any, api: any) {
  const eventsSocket = manager.socket('/events', {
    /*auth: {
      token: '123',
    },*/
  });
  eventsSocket.on('connect', () => {
    console.log('eventsSocket connected');
  });
  eventsSocket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      eventsSocket.connect();
    }
    // else the socket will automatically try to reconnect
  });
  eventsSocket.io.on('error', (error: any) => {
    console.error('eventsSocket error:', error);
  });


  // ====== Принимаем события от сервера
  eventsSocket.on('new_block_number', async (data) => {
    /*if (eventData > state.currentBlock) {
      state.currentBlock = eventData;
    }*/
    //console.log('eventsSocket on new_block_number', data);
    //dispatch(A.?.?);

    try {
      const res = await api.getState();
      if (res.ok) {
        dispatch(A.state.set(res.result));
      }
    } catch (error) { console.error(error); }
  });

  eventsSocket.on('new_participant', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('new_participant_data', (data) => {
    dispatch(A.lastParticipants.addOne(data));
  });

  eventsSocket.on('challenge_fees_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_deposit_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_winner_awarded_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_referrer_awarded_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_winner_claimed_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_referrer_missed_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_referrer_claimed_usdt', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_participant_joined_to_season', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });

  eventsSocket.on('challenge_started', (data) => {
    dispatch(A.lastEvents.addOne(data));
  });
}



const { store, persistor, api, } = configureStore(window?.__PRELOADED_STATE__);
delete window?.__PRELOADED_STATE__;


export default function Providers({ children, }: any) {
  const [ready, setReady] = useState(false);
  const [timerIntervalId, setTimerIntervalId] = useState();

  //const dispatch = useDispatch();

  const updateData = async () => {
    try {
      const res = await api.getState();
      if (res.ok) {
        store.dispatch(A.state.set(res.result));
      }
    } catch (error) { console.error(error); }

    try {
      const res = await api.getEvents({ limit: 100, page: 0, });
      if (res.ok) {
        store.dispatch(A.lastEvents.addBatch(res.result.list));
      }
    } catch (error) { console.error(error); }

    try {
      const res = await api.getParticipants({ limit: 100, page: 0, });
      if (res.ok) {
        store.dispatch(A.lastParticipants.addBatch(res.result.list));
      }
    } catch (error) { console.error(error); }
  }

  useEffect(() => {
    setupTimer(setTimerIntervalId, store.dispatch);
    setupEventsSocket(store.dispatch, api);
    setReady(true);

    updateData();
    return () => {
      clearInterval(timerIntervalId);
    };
  }, []);


  if (!ready) {
    return (null);
  }

  return (
    <>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <ApiContext.Provider value={api}>
              <Router>
                <ModalProvider value={modalsL1}>
                  <ModalProvider value={modalsL2}>
                    {children}
                  </ModalProvider>
                </ModalProvider>
              </Router>
            </ApiContext.Provider>
          </I18nextProvider>
        </PersistGate>
      </StoreProvider>

      <ToastContainer
        theme='dark'
        position='top-right'
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        //autoClose={100000}
        //rtl={false}
        //pauseOnFocusLoss
        //pauseOnHover
        //draggable
        style={{
          zIndex: 10000,
        }}
      />
    </>
  );
}
