const chainId = 56;
const chainName = 'bsc_mainnet';


const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
const usdtCreatedBlock = 176416; // deploy=176416

const wmcAddress = '0x36604f23FF3C9FAd6d16c931812eFAf4c17b29D9';
const wmcCreatedBlock = 32864571; // deploy=32864571

const wmcSaleAddress = null; // TODO
const wmcSaleCreatedBlock = 0; // TODO deploy=

const challengeIdAddress = null; // TODO
const challengeIdCreatedBlock = 0; // TODO deploy=
const challengeIdOpenSeaSlug = null; // TODO world-millionaire-challenge-id

const challengeIdDescriptorAddress = null; // TODO
const challengeIdDescriptorCreatedBlock = 0; // TODO deploy=

const challengeAddress = null; // TODO
const challengeCreatedBlock = 0; // TODO deploy=

const challengeDepositorAddress = null; // TODO
const challengeDepositorCreatedBlock = 0; // TODO deploy=


const timelockAddress = '0x67e1d2770237e509e8044967CF0F68aEA649C0b6';
const timelockCreatedBlock = 32864349; // deploy=32864349

const multisigAddress = '0x01B41030c1d98D356827204C0Fdb19fFBaeEb6b3';
const multisigCreatedBlock = 32864206; // deploy=32864206


const proxyAdminAddress = '0xD7e04D337476E8eEf10B00DB4D2F3D7fA32A0641';
const proxyAdminCreatedBlock = 32859509; // deploy=32859509


export default {
  bscChainId: chainId,
  bscChainName: chainName,

  bscUsdtAddress: usdtAddress,
  bscUsdtCreatedBlock: usdtCreatedBlock,

  bscWmcAddress: wmcAddress,
  bscWmcCreatedBlock: wmcCreatedBlock,

  bscWmcSaleAddress: wmcSaleAddress,
  bscWmcSaleCreatedBlock: wmcSaleCreatedBlock,

  bscChallengeIdAddress: challengeIdAddress,
  bscChallengeIdCreatedBlock: challengeIdCreatedBlock,
  bscChallengeIdOpenSeaSlug: challengeIdOpenSeaSlug,

  bscChallengeIdDescriptorAddress: challengeIdDescriptorAddress,
  bscChallengeIdDescriptorCreatedBlock: challengeIdDescriptorCreatedBlock,

  bscChallengeAddress: challengeAddress,
  bscChallengeCreatedBlock: challengeCreatedBlock,

  bscChallengeDepositorAddress: challengeDepositorAddress,
  bscChallengeDepositorCreatedBlock: challengeDepositorCreatedBlock,

  bscTimelockAddress: timelockAddress,
  bscTimelockCreatedBlock: timelockCreatedBlock,

  bscMultisigAddress: multisigAddress,
  bscMultisigCreatedBlock: multisigCreatedBlock,

  bscProxyAdminAddress: proxyAdminAddress,
  bscProxyAdminCreatedBlock: proxyAdminCreatedBlock,
};
