import { put, select } from 'redux-saga/effects';
import * as A from './actions';
import * as S from './selectors';



const logLocation = 'sagas/clock/sagas';

export default ({ api, }) => {

  const clearSaga = function* () {
    yield put(A.clear());
  }

  const updateSaga = function* () {
    let step = 0;
    try {
    } catch (error) {
      yield console.error(logLocation, 'updateSaga()', `step: ${step}`, error);
    }
  }


  return {
    clearSaga,
    updateSaga,
  }
}

