import { Routes, Route, Navigate, /*useLocation*/ } from 'react-router-dom';
import { routerPaths } from '@/router/helper';

import MainPage from '@/pages/Main';
import EventsPage from '@/pages/Events';
import ParticipantsPage from '@/pages/Participants';
import MyAccount from '@/pages/MyAccount';

import TokensPage from '@/pages/Tokens';


import FaqPage from '@/pages/FAQ';
import TermsPage from '@/pages/Terms';
import PrivacyPage from '@/pages/Privacy';

import ModalsPage from '@/pages/Modals';
//import PopupsPage from '@/pages/Popups';



export default function AppRoutes() {
  return (
    <Routes>
      <Route path={routerPaths.homePage} element={<MainPage />} />

      <Route path={routerPaths.myPage} element={<MyAccount />} />

      <Route path={routerPaths.eventsPage} element={<EventsPage />} />
      <Route path={routerPaths.participantsPage} element={<ParticipantsPage />} />
      <Route path={routerPaths.participantPage} element={<MyAccount />} />

      <Route path={routerPaths.idsPage} element={<TokensPage />} />

      <Route path={routerPaths.faqPage} element={<FaqPage />} />
      <Route path={routerPaths.termsPage} element={<TermsPage />} />
      <Route path={routerPaths.privacyPage} element={<PrivacyPage />} />

      {/*<Route path={routerPaths.unsubscribePage} element={?} />*/}

      <Route path='/modals' element={<ModalsPage />} />
      {/*<Route path='/popups' element={<PopupsPage />} />*/}

      <Route path='*' element={<Navigate to={routerPaths.homePage} />} />
    </Routes>
  );
}
