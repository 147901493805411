
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { default as C } from '@/constants';

export const appName = 'WMC.PRO';
export const appVersion = '0.1.0';


export const apiKey = '?';
export const rootDomain = C.isProduction
  ? 'https://wmc.pro'
  : `http://${window.location.hostname}:3000`;
export const apiDomain = C.isProduction
  ? 'https://api.wmc.pro'
  : `http://${window.location.hostname}:3100`;
export const wsDomain = C.isProduction
  ? 'wss://api.wmc.pro/'
  : `ws://${window.location.hostname}:3100/`;



export const defaultChainId = 5; // TODO goerly - 5, BSC - 56
export const infuraApiKey = '47d58c944d124ed3878624c4f448393d';

// https://chainlist.org/chain/56
const bscRpcEndpoints = [
  'https://bsc-dataseed.bnbchain.org',
  'https://bsc-dataseed.nariox.org',
  'https://bsc-dataseed.defibit.io',
  'https://bsc-dataseed.ninicoin.io',
  'https://bsc.nodereal.io',
  'https://bsc-dataseed-public.bnbchain.org',

  'https://bsc-dataseed1.binance.org/',
  'https://bsc-dataseed2.binance.org/',
  'https://bsc-dataseed3.binance.org/',
  'https://bsc-dataseed4.binance.org/',
  'https://bsc-dataseed1.defibit.io/',
  'https://bsc-dataseed2.defibit.io/',
  'https://bsc-dataseed3.defibit.io/',
  'https://bsc-dataseed4.defibit.io/',
  'https://bsc-dataseed1.ninicoin.io/',
  'https://bsc-dataseed2.ninicoin.io/',
  'https://bsc-dataseed3.ninicoin.io/',
  'https://bsc-dataseed4.ninicoin.io/',
];
export function getJsonRpcEndpointUrl(chainId) {
  switch (chainId) {
    case 1: return `https://mainnet.infura.io/v3/${infuraApiKey}`;
    case 5: return `https://goerli.infura.io/v3/${infuraApiKey}`;
    case 56: {
      let index = Math.floor(Math.random() * bscRpcEndpoints.length);
      if (index === bscRpcEndpoints.length) {
        index = bscRpcEndpoints.length - 1;
      }
      return bscRpcEndpoints[index];
    }
    default: return null;
  }
}

export const jsonRpcEndpointUrl = getJsonRpcEndpointUrl(defaultChainId);


export const usdtTokenAddress = '0x55d398326f99059fF775485246999027B3197955';
export const usdtTokenAddressGoerli = '0xc214B6B818357506dB183B19296FaF2B303D2531'; // TODO TEST TOKEN

export const wmcTokenAddress = '0x36604f23FF3C9FAd6d16c931812eFAf4c17b29D9';
export const wmcTokenAddressGoerli = '0x399dDa3E96E62fd00f2228d333A0D6a3ad07027C';
export const wmcTokenLinkExplorer = `https://bscscan.com/token/${wmcTokenAddress}`;
export const wmcTokenLinkCoinMarketCap = 'https://coinmarketcap.com/currencies/?/'; // TODO
export const wmcTokenLinkCoinGecko = 'https://www.coingecko.com/coins/?'; // TODO
export const wmcTokenLinkBuyPancakeSwap = `https://pancakeswap.finance/swap?outputCurrency=${wmcTokenAddress}&chain=bsc`;
export const wmcTokenSecurityAuditLink = 'https://skynet.certik.com/projects/?'; // TODO

export const wmcIdAddress = '?'; // TODO
export const wmcIdAddressGoerli = '0x818f55201bC137564E1BE101e32c5acCED804276'; // TODO
export const wmcIdLinkExplorer = `https://bscscan.com/token/${wmcIdAddress}`;
export const wmcIdLinkOpenSeaSlug = '?'; // TODO world-millionaire-challenge-id millionaire-id
export const wmcIdLinkOpenSea = `https://opensea.io/collection/${wmcIdLinkOpenSeaSlug}`;

export const wmcChallengeAddress = '?'; // TODO
export const wmcChallengeAddressGoerli = '0x4F6b791e4822b2295ED61643fc6Cf65DC76665a6'; // TODO
export const wmcChallengeLinkExplorer = `https://bscscan.com/address/${wmcChallengeAddress}`;
export const wmcChallengeSecurityAuditLink = 'https://skynet.certik.com/projects/?'; // TODO

export const wmcChallengeDepositorAddress = '?'; // TODO
export const wmcChallengeDepositorAddressGoerli = '0x26C9Ea906B6725C54FCAf7aFb77c5b4b67DeA9DD'; // TODO


export const twitterUsername = '?'; // TODO
export const twitterLink = `https://twitter.com/${twitterUsername}`;

export const gitHubLink = 'https://github.com/wmc-pro';
export const telegramLink = 'https://t.me/wmc_pro';
export const discordLink = 'https://discord.com/?'; // TODO
export const instagramLink = 'https://www.instagram.com/?/'; // TODO
export const mediumLink = 'https://medium.com/@?'; // TODO


export const whitepaperLink = '/wmc_whitepaper.pdf';




// 1. Get projectId at https://cloud.walletconnect.com
export const walletconnectProjectId = '844e9b68aebb6b5c3a1f72e47794620e';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: getJsonRpcEndpointUrl(1),
  rpcUrl: 'https://cloudflare-eth.com',
}
const goerli = {
  chainId: 5,
  name: 'Goerli',
  currency: 'goETH',
  explorerUrl: 'https://goerli.etherscan.io',
  rpcUrl: getJsonRpcEndpointUrl(5),
}
const bscMainnet = {
  chainId: 56,
  name: 'BSC',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: getJsonRpcEndpointUrl(56),
}
const arbitrumOne = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
}

export const chainsDefault = [];
if (defaultChainId === 1) chainsDefault.push(mainnet);
if (defaultChainId === 5) chainsDefault.push(goerli);
if (defaultChainId === 56) chainsDefault.push(bscMainnet);
if (defaultChainId === 42161) chainsDefault.push(arbitrumOne);


// 3. Create modal
export const metadata = {
  name: 'WMC.PRO',
  description: 'World Millionaire Challenge',
  url: 'https://wmc.pro',
  icons: ['https://avatars.githubusercontent.com/u/139248369'],
};

export const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: defaultChainId,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: getJsonRpcEndpointUrl(defaultChainId), // used for the Coinbase SDK
});

export const web3ModalConfig = {
  ethersConfig,
  chains: chainsDefault,
  defaultChain: chainsDefault[0],

  projectId: walletconnectProjectId,
  termsConditionsUrl: 'https://wmc.pro/#/terms',
  privacyPolicyUrl: 'https://wmc.pro/#/privacy',

  // https://docs.walletconnect.com/web3modal/react/theming
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 999
  },

  /*featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
  ],*/


  // USDT
  tokens: {
    56: {
      address: usdtTokenAddress,
      //image: '?', //optional
    },
    5: {
      address: usdtTokenAddressGoerli,
      //image: '?', //optional
    },
  },

  // WMC
  /*tokens: {
    56: {
      address: wmcTokenAddress,
      //image: '?' //optional
    },
    5: {
      address: wmcTokenAddressGoerli,
      //image: '?' //optional
    },
  },*/
};

// 3. Create modal
createWeb3Modal(web3ModalConfig);
