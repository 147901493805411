import React from "react"
import { useState, useEffect, } from "react"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';

import { useModal } from 'react-simple-modal-provider';


import {
  useWeb3Modal, useWeb3ModalAccount, useWeb3ModalState, useWeb3ModalEvents, useWeb3ModalProvider,
} from '@web3modal/ethers/react';


import { useMediaPredicate } from 'react-media-hook';
import { mobileBreakpoint } from '@/helpers/Media';

import { Link, LinkExternal } from "@/components/Link";
import styles from "./Header.module.scss"
import Logo from "@/assets/img/logo.svg?react"
import Hamburger from "@/assets/img/hamburger.svg?react"
import Button from "@/components/common/Button"
import LoginIcon from "@/assets/img/login.svg?react"
import WalletIcon from "@/assets/img/wallet.svg?react"
import CloseIcon from "@/assets/img/close.svg?react"
//import TonIcon from "@/assets/img/ton.svg?react"
//import PlusIcon from "@/assets/img/plus.svg?react"
//import ArrowRight from "@/assets/img/arrow-right.svg?react"
import Twitter from "@/assets/img/social/24/twitter.svg?react"
import Telegram from "@/assets/img/social/24/tg.svg?react"
import Medium from "@/assets/img/social/24/medium.svg?react"
import Instagram from "@/assets/img/social/24/instagram.svg?react"
//import Badge from "@/components/common/Badge"
//import Plus from "@/components/common/Plus"
//import { slicingHash } from "@/utils"
import { routerPaths } from "@/router/helper";
import {
  twitterLink, telegramLink, instagramLink, mediumLink,
  whitepaperLink, defaultChainId,
} from "@/config";

import ImageUser from "@/assets/img/main/user.png";
import ImageUserX2 from "@/assets/img/main/user@2x.png";



function Aside({
  isActiveNav,
  setActive,
  links,
}: {
  isActiveNav: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>,
  links: Array<{ title: string, to?: string, href?: string, }>,
}) {
  return (
    <aside
      className={`${styles.aside} ${isActiveNav ? styles["aside--active"] : ""}`}
    >
      <div className={styles.aside__top}>
        <Button
          className={styles.aside__close}
          onClick={() => setActive(false)}
          variant="text"
          icon={<CloseIcon />}
        />
        <Link to={routerPaths.homePage}>
          <Logo />
        </Link>
      </div>

      <div className={styles.aside__middle}>
        <nav className={styles.aside__nav}>
          {links.map((item) => {
            if (item.href)
              return (
                <LinkExternal
                  href={item.href}
                  key={item.title}
                  className={styles["aside__nav-item"]}
                >
                  {item.title}
                </LinkExternal>
              );

            else
              return (
                <Link
                  to={item.to}
                  key={item.title}
                  className={styles["aside__nav-item"]}
                >
                  {item.title}
                </Link>
              );
          })}
        </nav>
      </div>

      <div className={styles.aside__bottom}>
        <LinkExternal href={twitterLink} className={styles.aside__social}>
          <Twitter />
        </LinkExternal>
        <LinkExternal href={instagramLink} className={styles.aside__social}>
          <Instagram />
        </LinkExternal>
        <LinkExternal href={telegramLink} className={styles.aside__social}>
          <Telegram width="30" />
        </LinkExternal>
        <LinkExternal href={mediumLink} className={styles.aside__social}>
          <Medium />
        </LinkExternal>
      </div>
    </aside>
  );
}


function ConnectWalletButton() {
  const { t } = useTranslation('Header');

  const isMobile = useMediaPredicate(mobileBreakpoint);


  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open, selectedNetworkId } = useWeb3ModalState();
  const events = useWeb3ModalEvents();
  //console.log('events', events)
  const { walletProvider, walletProviderType } = useWeb3ModalProvider();
  //console.log('walletProviderType', walletProviderType, walletProvider)


  const [loading, setLoading] = useState(false);
  async function onConnectWallet() {
    setLoading(true);
    await openWeb3Modal();
    setLoading(false);
  }


  if (!isConnected)
    return (
      <Button
        className={styles.header__button}
        variant="outlined"
        onClick={onConnectWallet}
        text={isMobile ? undefined : t('Connect Wallet')}
        icon={isMobile ? <WalletIcon /> : undefined}
      />
    );

  if (isMobile) {
    if (chainId === defaultChainId) {
      // className={`${styles.header__button} ${styles["header__button--md"]}`}
      return (
        <Button
          className={`${styles.header__button}`}
          variant="outlined"
          icon={<WalletIcon />}
          onClick={onConnectWallet}
        />
      );
    } else {
      return (<w3m-network-button />);
    }
  }

  if (chainId === defaultChainId) {
    return (<w3m-account-button balance={'hide'} />);
  } else {
    return (<w3m-network-button />);
  }
}


function LoginButton() {
  const { t } = useTranslation('Header');
  const navigate = useNavigate();

  const isMobile = useMediaPredicate(mobileBreakpoint);

  const { open: openLoginModal } = useModal('LoginModal');

  const isAuthenticated = useSelector((state) => S.profile.isAuthenticated(state));
  const email = useSelector((state) => S.profile.getEmail(state));
  const refId = useSelector((state) => S.profile.getRefId(state));

  const [loading, setLoading] = useState(false);
  const onLogin = () => {
    setLoading(true);
    openLoginModal({});
    setLoading(false);
  }

  const goToMyProfile = () => {
    navigate(routerPaths.myPage);
  }

  if (!isAuthenticated)
    return (
      <Button
        className={styles.header__button}
        onClick={onLogin}
        text={isMobile ? undefined : t('Login')}
        icon={isMobile ? <LoginIcon /> : undefined}
        disabled={loading}
      />
    );

  return (
    <>
      <div className={styles.avatar__user} onClick={goToMyProfile}>
        <img
          src={ImageUser}
          srcSet={`${ImageUserX2} 2x`}
          alt=""
          className={styles.account__avatar}
        />
      </div>
      <div className={styles.account__badge} onClick={goToMyProfile}>
        <div className={styles.account__balance}>
        </div>
      </div>

      <div className={`${styles.account__info} d-none d-md-flex`} onClick={goToMyProfile}>
        <div className={styles["account__info-wrapper"]}>
          <p className={styles.account__name}>
            {email}
          </p>
          <div className={styles.account__balance}>
            REF ID: {refId ? refId.toLocaleString('en') : null}
          </div>
        </div>
        {/*<ArrowRight />*/}
      </div >
    </>
  );
}


function CurrentState() {
  const { t } = useTranslation('Header');

  const isMobile = useMediaPredicate(mobileBreakpoint);

  /*if (false) {
    return (
      <>
        <div
          className={`col-xl-2 col-lg-4 col-6 ${styles.header__right} ${styles["header__right--lg"]}`}
        >
          <ConnectWalletButton />
          <LoginButton />
        </div>

        <div className={`col-xl-2 col-lg-4 col-6 ${styles.header__right}`}>
          <ConnectWalletButton mini={true} />
          <LoginButton mini={true} />
        </div>
      </>
    );
  }*/

  return (
    <div
      className={`col-xl-2 col-lg-4 col-6 ${styles["header__right--auth"]}`}
    >
      <div className={styles.account}>
        <div className={styles.account__wallet}>
          <ConnectWalletButton />
        </div>

        <div className={styles.account__profile}>
          <LoginButton />
        </div>
      </div>
    </div>
  );
}


export default function Header({ isAuth }: { isAuth?: boolean }) {
  const { t } = useTranslation('Header');
  const [isActiveNav, setActiveNav] = useState(false);

  const links = [
    { title: t('How to win'), to: "/#roadmap", },
    { title: t('Referral System'), to: "/#referral-system", },
    { title: t('Whitepaper'), href: whitepaperLink, },
    { title: t('Team'), to: "/#team", },
    { title: t('WMC Token'), to: "/#wmc-token", },
    /*{ title: t('Roadmap'), to: "/#roadmap", },
    { title: t('Tokenomics'), to: "/#tokenomics", },*/
    { title: t('FAQ'), to: "/#faq", },
  ];


  return (
    <>
      <Aside isActiveNav={isActiveNav} setActive={setActiveNav} links={links} />
      <header className={styles.header}>
        <div className="container-fluid">
          <div className="row align-items-center">

            <div className="col-xl-2 col-lg-1 col-6">
              <div className={styles.header__logo}>
                <Button
                  onClick={() => setActiveNav(true)}
                  variant="text"
                  icon={<Hamburger />}
                  className={styles.header__hamburger}
                />
                <Link to={routerPaths.homePage}>
                  <Logo />
                </Link>
              </div>
            </div>

            <div className="col-xl-8 col-lg-7 d-none d-lg-block">
              <nav className={styles.nav}>
                {links.map((item) => {
                  if (item.href)
                    return (
                      <LinkExternal
                        href={item.href}
                        key={item.title}
                        className={styles.nav__item}
                      >
                        {item.title}
                      </LinkExternal>
                    );

                  else
                    return (
                      <Link
                        to={item.to}
                        key={item.title}
                        className={styles.nav__item}
                      >
                        {item.title}
                      </Link>
                    );
                })}
              </nav>
            </div>

            <CurrentState />
          </div>
        </div>
      </header>
    </>
  );
}
