import styles from "./Hint.module.scss"
import { useState, useEffect, } from 'react';
import { useModal } from 'react-simple-modal-provider';
import { useNavigate } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import ImageHintGradient from "@/assets/img/hint/gradient.png";
import ImageHintGradientX2 from "@/assets/img/hint/gradient@2x.png";

import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';

import {
  useWeb3Modal, useWeb3ModalAccount,
} from '@web3modal/ethers/react';

import Button from '@/components/common/Button';
import { NumericFormat as Number } from 'react-number-format';

import { routerPaths } from "@/router/helper";



export default function Hint() {
  const { t } = useTranslation('Roadmap');
  const navigate = useNavigate();

  const { open: openLoginModal } = useModal('LoginModal');
  const isAuthenticated = useSelector((state) => S.profile.isAuthenticated(state));

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();

  const onJoinRightNow = async () => {
    if (!isAuthenticated) {
      openLoginModal({});
    } else if (!isConnected) {
      await openWeb3Modal();
    } else {
      navigate(routerPaths.myPage);
    }
  }

  const challengeState = useSelector((state) => S.state.getState(state));
  const [participantsRest, setParticipantsRest] = useState(challengeState.participantsForStart);
  useEffect(() => {
    const delta = challengeState.participantsForStart - challengeState.participants;
    if (delta < 0) {
      setParticipantsRest(0);
    } else {
      setParticipantsRest(delta);
    }
  }, [challengeState.participants, challengeState.participantsForStart]);

  return (
    <div className={styles.hint}>
      <img
        src={ImageHintGradient}
        srcSet={`${ImageHintGradientX2} 2x`}
        alt="Roadmap image"
        className={styles.hint__shadow}
      />
      <p className={styles.hint__text}>
        Reaching <Number
          className={styles["hint__text--colored"]}
          displayType='text' thousandSeparator
          value={100000} suffix=' participants'
        />{' '} And we are launching daily draw
      </p>
      <br />

      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-md-auto'>
            <Number
              displayType='text' thousandSeparator
              value={participantsRest} suffix=' participants till start'
            />
          </div>
          <div className='col-md-auto'>
            <Button
              text="Join right now!"
              onClick={onJoinRightNow}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
