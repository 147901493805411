import { /*AlchemyProvider,*/ JsonRpcProvider } from 'ethers';
import { defaultChainId, getJsonRpcEndpointUrl, } from '@/config';



const ethresProvider = new JsonRpcProvider(getJsonRpcEndpointUrl(defaultChainId));


export default ({
}) => {
  const getGlobalEthresProvider = () => {
    return ethresProvider;
  }

  return {
    getGlobalEthresProvider,
  };
}
