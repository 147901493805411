import { default as C } from '@/constants';



export default function getExplorerTxLink(chainId: number, transactionHash: string): string {
  switch (chainId) {
    case 1: return `https://etherscan.io/tx/${transactionHash}`;
    case C.bscChainId: return `https://bscscan.com/tx/${transactionHash}`;
    case C.goerliChainId: return `https://goerli.etherscan.io/tx/${transactionHash}`;
    default: return '';
  }
}
