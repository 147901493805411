import ConfirmOtpCodeModal from './ConfirmOtpCodeModal';
import LoginModal from './LoginModal';



export const modalsL1 = [
  ConfirmOtpCodeModal,
];
export const modalsL2 = [
  LoginModal,
];
