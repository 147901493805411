import styles from "./Table.module.scss"
import Member from "@/assets/img/member.svg?react"
import Deposit from "@/assets/img/deposit.svg?react"
import Win from "@/assets/img/win.svg?react"
import Nft from "@/assets/img/nft.svg?react"
import Tokens from "@/assets/img/tokens.svg?react"
import Button from "@/components/common/Button"
import ArrowRight from "@/assets/img/arrow-right.svg?react"
//import { slicingHash } from "@/utils"


import { getExplorerTxLink } from "@/hooks";
import { routerPaths } from "@/router/helper";
import { Link, LinkExternal } from "@/components/Link";
import { NumericFormat as Number } from 'react-number-format';
import moment from 'moment';



function TableEventsAdaptiveItem({
  index,
  data,
}: {
  index: number
  data?: any
}) {

  function getInfo(name: string, isColor: boolean): string | JSX.Element {
    switch (name) {
      case "new_participant":
        return isColor ? "green" : <Member />
      case "challenge_fees_usdt":
      case "challenge_deposit_usdt":
        return isColor ? "magenta" : <Deposit />
      case "challenge_winner_awarded_usdt":
      case "challenge_winner_claimed_usdt":
        return isColor ? "yellow" : <Win />
      case "challenge_participant_joined_to_season":
        return isColor ? "pink" : <Nft />
      case "challenge_referrer_awarded_usdt":
      case "challenge_referrer_claimed_usdt":
      case "challenge_referrer_missed_usdt":
        return isColor ? "blue" : <Tokens />
      case "challenge_started":
    }
    return "";
  }

  return (
    <div
      key={index}
      className={`${styles.item} ${index % 2 === 0 ? styles["item--odd"] : ""}`}
    >
      <div className={styles.item__top}>
        <div
          // ${!hash?.length ? styles.hidden : ""}
          // ${styles.hidden}
          className={`${styles.item__code}`}
        >
          <span className={styles.item__button}>
            {!isNaN(data?.accountId)
              ? <Link to={routerPaths.participantPage.replace(':participantId', data.accountId)}>
                Millionaire ID <Number
                  displayType='text' thousandSeparator
                  value={data.accountId} prefix='#'
                />
              </Link>
              : ''
            }
          </span>
        </div>

        <div
          className={`${styles.item__event} ${styles[getInfo(data.eventName, true)]}`}
        >
          {getInfo(data.eventName, false)}
          <span>
            {!isNaN(data.data?.seasonId) && <Number
              displayType='text' thousandSeparator
              value={data.data?.seasonId} prefix='#'
            />}
            {!isNaN(data.data?.amountFloat) && <Number
              displayType='text' thousandSeparator fixedDecimalScale
              decimalScale={data.data?.amountFloat < 1 ? 2 : 0}
              value={data.data?.amountFloat} suffix=' USDT'
            />}
          </span>
        </div>
      </div>

      <div className={styles.item__bottom}>
        <span className={styles.item__date}>
          {new Date(data.blockTime).toLocaleString()} ({moment(data.blockTime).fromNow()})
        </span>

        <div
          className={styles.item__action}
        /*onClick={() => window.open(getExplorerTxLink(
          data?.chainId,
          data?.transactionHash
        ))}*/
        >
          <LinkExternal
            href={getExplorerTxLink(
              data?.chainId,
              data?.transactionHash
            )}
          >
            <Button
              className={styles.item__button} variant="text"
              text="More"
            />
          </LinkExternal>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
}


export default function TableEventsAdaptive({ data }: { data: any[] }) {
  return (
    <div className={styles.table}>
      {data.map((item, index) => {
        return (
          <TableEventsAdaptiveItem
            key={index}
            data={item}
            index={index}
          />
        );
      })}
    </div>
  );
}
