import { getIn, } from 'immutable';
//import * as R from 'ramda';



const root = 'lastEvents';
export const getList = (state) => {
  return getIn(state, [root, 'list'], []);
}

export const getListWithLimit = (state, limit = 10) => {
  return getList(state).slice(0, limit);
}
