import styles from "./DefaultLayout.module.scss"
import Seo from '@/components/app/Seo';
import Header from '@/components/app/Header';
import Footer from '@/components/app/Footer';



export default function DefaultLayout({ title, description, meta, children }) {
  return (
    <div id="main" className="layout">
      <Seo
        title={title}
        description={description}
        meta={meta ? meta : []}
      />
      <Header />
      <main className="main-container">
        {children}
      </main>
      <Footer />
    </div>
  );
};
