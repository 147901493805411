import styles from "./Code.module.scss"
import { useState, useContext, } from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@/components/common/Button"
import OtpInput from "./OtpInput";



interface ICode {
  otp: string | undefined;
  setOtp: (otp: string) => void;
  onResendCode: () => void;
  resendCodeRestTime: number;
}
function Code({ otp, setOtp, onResendCode, resendCodeRestTime, }: ICode) {
  //const { t } = useTranslation();

  return (
    <div className={styles.code}>
      <OtpInput
        containerStyle={styles.code__top}
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span>-</span>}
        skipDefaultStyles={true}
        shouldAutoFocus={true}
        inputStyle={styles.code__input}
        inputType='number'
        renderInput={(props) => <input
          {...props}
        />}
      />


      <div className={styles.code__bottom}>
        <Button
          className={styles.code__button}
          variant="text"
          text="Resend code"
          onClick={onResendCode}
        />
        <p className={styles.code__timer}>
          {Math.floor(resendCodeRestTime / 60).toString().padStart(2, '0')}:
          {(resendCodeRestTime % 60).toString().padStart(2, '0')}
        </p>
      </div>
    </div>
  );
}

export default Code
