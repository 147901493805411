import styles from './MyAccount.module.scss';

// Import Swiper styles
import 'swiper/css';


//import { Swiper, SwiperSlide } from 'swiper/react';
//import { Navigation } from 'swiper/modules';

//import ArrowLeft from '@/assets/img/arrow-left.svg?react';
//import ArrowRight from '@/assets/img/arrow-right.svg?react';
//import EditIcon from '@/assets/img/edit.svg?react';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
//import TokenIcon from '@/assets/img/ton.svg?react';
import Badge from '@/components/common/Badge';

import ImageParticipantsBg1 from '@/assets/img/participants/participants-bg-1.png';
import ImageParticipantsBg1X2 from '@/assets/img/participants/participants-bg-1@2x.png';
import ImageParticipantsBg2 from '@/assets/img/participants/participants-bg-2.png';
import ImageParticipantsBg2X2 from '@/assets/img/participants/participants-bg-2@2x.png';
import ImageUser from '@/assets/img/main/user.png';
import ImageUserX2 from '@/assets/img/main/user@2x.png';
//import ImageLogoCompressed from '@/assets/img/main/logo-compressed.svg;

import DefaultLayout from '@/layouts/DefaultLayout';


import ApiContext from '@/api/api-context';
import { useEffect, useRef, useState, useContext, } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import { useSelector, useDispatch, } from 'react-redux';
import * as S from '@/store/selectors';
import * as A from '@/store/actions';

import {
  useWeb3Modal, useWeb3ModalAccount, useWeb3ModalState, useWeb3ModalEvents, useWeb3ModalProvider,
} from '@web3modal/ethers/react';
import { BrowserProvider, parseEther, Signature, verifyTypedData, } from 'ethers';
import BN from 'bignumber.js';


import { v4 as uuidv4 } from 'uuid';

import { routerPaths } from '@/router/helper';
import * as HOOK from '@/hooks';
import * as CONF from '@/config';
import { default as CONST } from '@/constants';




function SubscriptionItem(
  { title, price, buttonText, onClick, }:
    { title: string; price: string, buttonText: string, onClick: () => void, }
) {
  return (
    <div className={styles.subscritpion}>
      <div className={styles.subscritpion__top}>
        <h5 className="subscritpion__title">{title}</h5>
        <span className={styles.subscritpion__price}>
          <Badge text={price} />
        </span>
      </div>

      <Button
        className={styles.subscritpion__button}
        text={buttonText}
        onClick={onClick}
      />
    </div>
  );
}


const initialState = {
  email: '', guid: 0,
  wallet: {
    main: '',
    list: [],
  },
};



const now = () => Math.floor(Date.now() / 1000);


export default function Account() {
  const api = useContext(ApiContext);

  const isAuthenticated = useSelector((state) => S.profile.isAuthenticated(state));
  const email = useSelector((state) => S.profile.getEmail(state));
  const refId = useSelector((state) => S.profile.getRefId(state));
  const refGuid = useSelector((state) => S.profile.getReferrerId(state));

  const handleCopyRefLink = () => {
    copy(`https://wmc.pro/?r=${refId}`);
    toast.info('Copied!');
  }

  const onLogOut = () => {
    dispatch(A.profile.clear());
  }
  const navigate = useNavigate();
  useEffect(() => {
    /*if (!isAuthenticated) {
      navigate(routerPaths.homePage);
    }*/
  }, [isAuthenticated]);


  //const swiperRef = useRef();
  const dispatch = useDispatch();

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  //const { open, selectedNetworkId } = useWeb3ModalState();
  //const events = useWeb3ModalEvents();
  //console.log('events', events)
  const { walletProvider } = useWeb3ModalProvider();


  const [subscriptionIsActive, setSubscriptionIsActive] = useState(false);
  const [subscriptionActiveUntil, setSubscriptionActiveUntil] = useState('');

  const updateSubsInfo = () => {
    if (isConnected && address && walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const challengeContract = HOOK.getChallengeContract(CONF.defaultChainId, ethersProvider);

      challengeContract.balanceOf(address)
        .then((days) => {
          days = Number(days);
          if (days > 0) {
            setSubscriptionIsActive(true);
            const time = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
            setSubscriptionActiveUntil(time.toLocaleDateString());
          } else {
            setSubscriptionIsActive(false);
            setSubscriptionActiveUntil('');
          }
        })
        .catch(console.error);
    } else {
      setSubscriptionIsActive(false);
      setSubscriptionActiveUntil('');
    }
  }
  useEffect(() => {
    updateSubsInfo();
  }, [isConnected, address, walletProvider]);


  /**
   * Функция делает депозин в смарт контракт.
   * @param item - сколько сезонов
   * @returns 
   */
  const onSubscribe = async (item: string) => {
    let numSeasons = 0;
    switch (item) {
      case 'seasons_1': {
        numSeasons = 1;
        break;
      }
      case 'seasons_2': {
        numSeasons = 2;
        break;
      }
      case 'seasons_4': {
        numSeasons = 4;
        break;
      }
      default: {
        return;
      }
    }


    if (!isConnected) {
      return openWeb3Modal();
    } else if (chainId !== CONF.defaultChainId) {
      if (window.ethereum) {
        return window.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${CONF.defaultChainId.toString(16)}` }],
        });
      }
      const defNetName = CONF.defaultChainId === 56 ? 'BSC' : 'Goerli'
      return toast.error(`Switch Network to ${defNetName}`);
    } else {
      if (walletProvider && api) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();

        const usdtContract = HOOK.getUsdtContract(CONF.defaultChainId, signer);

        const depositorAddress = HOOK.getChallengeDepositorAddress(CONF.defaultChainId);
        const depositorContract = HOOK.getChallengeDepositorContract(CONF.defaultChainId, signer);

        try {
          const allowance = await usdtContract.allowance(address, depositorAddress);
          let { numDays, usdtAmount, usdtFees } = await depositorContract.getDepositDaysAmountFees(address, numSeasons);
          numDays = Number(numDays);

          const usdtTotal = usdtAmount + usdtFees;
          if (usdtTotal > allowance) {
            let tx = await usdtContract.approve(depositorAddress, usdtTotal);
            toast.success('USDT Approve pending');
            await tx.wait(1);
            toast.success('USDT Approve successful');
          }

          let nonce = await depositorContract.walletNonce(address);
          nonce = Number(nonce) + 1;

          const valueDepositRequest = {
            id: uuidv4(),
            guid: refId,
            timestamp: now(),
            chainId: CONF.defaultChainId,
            wallet: address,
            nonce,
            refGuid,
            numSeasons,
            numDays,
          };

          const signatureDepositRequest = await signer.signTypedData(
            CONST.depositRequestDomain,
            CONST.depositRequestTypes,
            valueDepositRequest
          );
          const res: any = await api.sendDepositRequest(valueDepositRequest, signatureDepositRequest);
          if (!res.ok) {
            console.error(res);
            toast.error('Wrong Server Response');
            return;
          }

          const tx = await depositorContract.deposit(
            res.result.value.nonce,
            res.result.value.numSeasons,
            res.result.value.referrerId,
            res.result.signature
          );
          toast.success('Deposit pending');
          await tx.wait(1);
          toast.success('Deposit successful');
          updateSubsInfo();
        } catch (error) {
          console.error(error);
          toast.error('Deposit fail');
        }
      }
    }
  }


  return (
    <DefaultLayout title='Your account'>
      <div className={styles.main}>
        <div className={styles.account}>
          <img
            src={ImageParticipantsBg1}
            srcSet={`${ImageParticipantsBg1X2} 2x`}
            alt="WMC.PRO My Account Page Background Image 1"
            title="WMC.PRO My Account Page Background Image 1"
            className={styles.account__bg1}
          />
          <img
            src={ImageParticipantsBg2}
            srcSet={`${ImageParticipantsBg2X2} 2x`}
            alt="WMC.PRO My Account Page Background Image 2"
            title="WMC.PRO My Account Page Background Image 2"
            className={styles.account__bg2}
          />
          <div className={`${styles.account__relative} container-xxl`}>
            <h1 className={styles.account__title}>Your account</h1>
            <div className={styles.account__content}>
              <div className="row gap">
                <div className="col-12 col-lg-3 mb-4 mb-lg-0">
                  <div className={styles.avatar}>
                    <div className={styles.avatar__wrapper}>
                      <div className={styles.avatar__user}>
                        <img
                          src={ImageUser}
                          srcSet={`${ImageUserX2} 2x`}
                          alt="WMC.PRO My Account Image"
                          title="WMC.PRO My Account Image"
                          className={styles.avatar__img}
                        />
                        {/*<div className={styles.avatar__edit}>
                          <div className={styles.avatar__button}>
                            <EditIcon />
                          </div>
                        </div>*/}
                      </div>
                      <div>
                        <h2 className={styles.avatar__title}>{email}</h2>
                        <p className={styles.avatar__desc}>REF ID: {refId ? refId.toLocaleString('en') : null}</p>
                        <Button
                          variant='outlined'
                          text="Log out"
                          //className={styles["account__logout--md"]}
                          className={styles["account__logout"]}
                          onClick={onLogOut}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-9">
                  <div className={styles.account__list}>
                    <div className={styles.account__block}>
                      <div className={styles.account__top}>
                        <h3 className={styles.account__subtitle}>
                          Your personal data
                        </h3>
                      </div>
                      <div className="row mb-4 mb-md-0 mb-lg-4">
                        {/*<div className="col-12 col-md-6 mb-4 mb-md-0">
                          <Input
                            label="Username"
                            value="VinoCosta"
                            onChange={() => { }}
                            changeble={true}
                          />
                        </div>*/}

                        <div className="col" /*className="col-12 col-md-6"*/>
                          <Input
                            label="Your email"
                            value={email}
                            //onChange={() => { }}
                            //changeble={true}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Input
                            label="Your referral link *"
                            value={`https://wmc.pro/?r=${refId}`}
                            onChange={() => { }}
                            isCopy={true}
                            onCopy={handleCopyRefLink}
                            readOnly
                          />
                        </div>
                      </div>
                      <br />
                      <p className={styles.notes_text}>
                        * Your referral link will only work if your main wallet has an active subscription!
                      </p>
                      <br />

                      <div className="row">
                        <div className="col">
                          <Input
                            label="Your wallet"
                            value={address}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/*<div className={styles.account__block}>
                      <div className={styles.account__top}>
                        <h3 className={styles.account__subtitle}>Payments</h3>
                      </div>
                      <div className="row g-4">
                        <div className="col-12 col-md-4">
                          <Input
                            label="Deposited"
                            value="90 USDT"
                            onChange={() => { }}
                            icon={<TokenIcon />}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <Input
                            label="Claimed"
                            value="1.000.000 USDT"
                            onChange={() => { }}
                            icon={<TokenIcon />}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <Input label="Wins" value="1" onChange={() => { }} />
                        </div>
                      </div>
                    </div>*/}

                    <div className={styles.account__block}>
                      <div className={styles.account__top}>
                        <h3 className={styles.account__subtitle}>
                          Wallet subscription
                        </h3>
                        <div className={`${styles.account__date} ${subscriptionIsActive ? styles.account__date__active : ''} mt-4 mt-sm-0`}>
                          {subscriptionIsActive
                            ? <>Active / until {subscriptionActiveUntil}</>
                            : <>Not active</>
                          }

                        </div>
                      </div>
                      <div className="row g-4">
                        <div className="col-12 col-md-4">
                          <SubscriptionItem
                            title="90 days" price="93 USDT"
                            buttonText={subscriptionIsActive ? 'Extend' : 'Subscribe'}
                            onClick={() => onSubscribe('seasons_1')}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <SubscriptionItem
                            title="180 days" price="186 USDT"
                            buttonText={subscriptionIsActive ? 'Extend' : 'Subscribe'}
                            onClick={() => onSubscribe('seasons_2')}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <SubscriptionItem
                            title="360 days" price="372 USDT"
                            buttonText={subscriptionIsActive ? 'Extend' : 'Subscribe'}
                            onClick={() => onSubscribe('seasons_4')}
                          />
                        </div>
                      </div>
                    </div>

                    {/*<div className={styles.account__block}>
                      <div className={styles.account__top}>
                        <h3 className={styles.account__subtitle}>All Active Wallets</h3>
                        <div className={styles.account__nav}>
                          <button
                            onClick={() => swiperRef.current?.slidePrev()}
                          >
                            <ArrowLeft
                              className={styles["account__nav-icon"]}
                            />
                          </button>
                          <button
                            onClick={() => swiperRef.current?.slideNext()}
                          >
                            <ArrowRight
                              className={styles["account__nav-icon"]}
                            />
                          </button>
                        </div>
                      </div>
                      <Swiper
                        modules={[Navigation]}
                        spaceBetween={16}
                        slidesPerView={4}
                        onSlideChange={() => console.log("slide change")}
                        onBeforeInit={(swiper) => {
                          swiperRef.current = swiper
                        }}
                      >
                        <SwiperSlide>
                          <Link to="/" className={styles.account__item}>
                            <img
                              src={ImageLogoCompressed}
                              alt=""
                              className={styles["account__item-img"]}
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Link to="/" className={styles.account__item}>
                            <img
                              src={ImageLogoCompressed}
                              alt=""
                              className={styles["account__item-img"]}
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Link to="/" className={styles.account__item}>
                            <img
                              src={ImageLogoCompressed}
                              alt=""
                              className={styles["account__item-img"]}
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Link to="/" className={styles.account__item}>
                            <img
                              src={ImageLogoCompressed}
                              alt=""
                              className={styles["account__item-img"]}
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Link to="/" className={styles.account__item}>
                            <img
                              src={ImageLogoCompressed}
                              alt=""
                              className={styles["account__item-img"]}
                            />
                          </Link>
                        </SwiperSlide>
                      </Swiper>
                      {/*<Button
                        text="Buy tokens"
                        className={styles.account__action}
                      />* /}
                  </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '25px' }}>
        </div>
      </div>
    </DefaultLayout >
  );
}
