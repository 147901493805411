import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const set = (payload) => ({
  type: AT.SET,
  payload: payload,
});
