import { put, select } from 'redux-saga/effects';
import queryString from 'query-string';
import * as A from './actions';
import * as S from './selectors';



const logLocation = 'sagas/profile/sagas';

export default ({ api, }) => {

  const clearSaga = function* () {
    yield put(A.clear());
  }

  /*const profileLogInSaga = function* (action) {
    const { payload } = action;

    try {
      const res = yield api.profileLogIn(payload);
      if (res.ok) {
        const { token, id, email, refGuid, /*username,* / permissions, authenticated, } = res.result;
        yield put(A.setAuth({
          authenticated,
          id,
          email,
          refGuid,
          //username,
          permissions,
          token,
        }));
        localStorage.setItem('refGuid', refGuid);
      }
    } catch (error) {
      yield console.error(logLocation, 'profileLogInSaga()', error);
    }
  }*/

  const profileLogOutSaga = function* () {
    try {
      yield api.logOut();
      yield put(A.setAuth({
        authenticated: false,
        id: null,
        email: null,
        //username: null,
        permissions: [],
        token: null,
        refGuid: null,
      }));
    } catch (error) {
      yield console.error(logLocation, 'profileLogOutSaga()', error);
    }
    yield put(A.clear());
  }

  const updateSaga = function* () {
    try {
      const inQueryParsed = queryString.parse(window.location.search);
      const inLocalStorage = localStorage.getItem('refGuid');
      const inStore = yield select(S.getReferrerId);
      const isAuthenticated = yield select(S.isAuthenticated);

      // Если авторизован записываем то что в базе на сервере
      if (isAuthenticated) {
        //yield put(A.setParent(inQueryParsed.r));
        localStorage.setItem('refGuid', inStore);
        return;
      }
      // если не авторизован
      else {
        if (inQueryParsed.r) {
          // доверяем ссылке
          yield put(A.setParent(inQueryParsed.r));
          localStorage.setItem('refGuid', inQueryParsed.r);
        } else if (inLocalStorage) {
          // ссылка пустая, но есть в localStorage
          yield put(A.setParent(inLocalStorage));
        } else {
          // Ни в localStorage ни в ссылке нет реф параметра
          yield put(A.setParent(null));
          localStorage.setItem('refGuid', null);
        }
      }

      /*if (inQueryParsed.utm_source && inQueryParsed.utm_source.includes('blog0') && !inStore) {
        yield put(A.setParent(inQueryParsed.utm_source));
      }*/
    } catch (error) {
      yield console.error(logLocation, 'updateSaga()', error);
    }
  }

  return {
    clearSaga,
    updateSaga,
    //profileLogInSaga,
    profileLogOutSaga,
  }
}

