import styles from "./FAQ.module.scss"
import { useTranslation } from 'react-i18next';
import ArriowIcon from "@/assets/img/arrow-left.svg?react"
import { Link } from "react-router-dom"
import { routerPaths } from "@/router/helper";

import Accordeon from "@/components/elements/Accordeon";
import ImageFaq from "@/assets/img/faq/faq.png";
import ImageFaqX2 from "@/assets/img/faq/faq@2x.png";
import ImageFaqGradient from "@/assets/img/faq/gradient.png";
import ImageFaqGradientX2 from "@/assets/img/faq/gradient@2x.png";

import ImageParticipantsBg1 from "@/assets/img/participants/participants-bg-1.png";
import ImageParticipantsBg1X2 from "@/assets/img/participants/participants-bg-1@2x.png";
import ImageParticipantsBg2 from "@/assets/img/participants/participants-bg-2.png";
import ImageParticipantsBg2X2 from "@/assets/img/participants/participants-bg-2@2x.png";

import DefaultLayout from "@/layouts/DefaultLayout";



export default function Faq() {
  const { t } = useTranslation('Faq');

  const faq = [
    { title: t('Q1'), description: t('A1'), },
    { title: t('Q2'), description: t('A2'), },
    { title: t('Q3'), description: t('A3'), },
    { title: t('Q4'), description: t('A4'), },
    { title: t('Q5'), description: t('A5'), },
    { title: t('Q6'), description: t('A6'), },
  ];

  return (
    <DefaultLayout title='FAQ'>
      <div className={styles.main}>
        <div className={styles.faq}>
          <img
            src={ImageParticipantsBg1}
            srcSet={`${ImageParticipantsBg1X2} 2x`}
            alt=""
            title=""
            className={styles.faq__bg1}
          />
          <img
            src={ImageParticipantsBg2}
            srcSet={`${ImageParticipantsBg2X2} 2x`}
            alt=""
            title=""
            className={styles.faq__bg2}
          />
          <div className={`${styles.faq__relative} container-xl`}>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <Link to={routerPaths.homePage} className={styles.faq__back}>
                  <ArriowIcon />
                  Back
                </Link>
                <div className={styles.faq__content}>
                  <div className={styles.faq__block}>
                    <h1 className={styles.faq__title}>FAQ</h1>
                    {faq.map((item, idx) => (
                      <Accordeon
                        key={idx}
                        title={item.title}
                        description={item.description}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
