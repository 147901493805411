const chainId = 5;
const chainName = 'ethereum_goerli';


const usdtAddress = '0xc214B6B818357506dB183B19296FaF2B303D2531';
const usdtCreatedBlock = 10137538; // deploy=10137538

const wmcAddress = '0x027CCFE6905Fd3Ff11EC4A605B9A0Df11aEf8e56';
const wmcCreatedBlock = 10137283; // deploy=10137283

const wmcSaleAddress = '0x07eC46900da227B5Fc2D039Da5A0dC829710775b';
const wmcSaleCreatedBlock = 10137299; // deploy=10137299

const challengeIdAddress = '0x818f55201bC137564E1BE101e32c5acCED804276';
const challengeIdCreatedBlock = 10137354; // deploy=10137354
const challengeIdOpenSeaSlug = 'world-millionaire-challenge-id-1';

const challengeIdDescriptorAddress = '0x6f86a30CEB8CD2947A50d809a2f19606B9c8f7B9';
const challengeIdDescriptorCreatedBlock = 10137373; // deploy=10137373

const challengeAddress = '0x4F6b791e4822b2295ED61643fc6Cf65DC76665a6';
const challengeCreatedBlock = 10137508; // deploy=10137508

const challengeDepositorAddress = '0x26C9Ea906B6725C54FCAf7aFb77c5b4b67DeA9DD';
const challengeDepositorCreatedBlock = 10137538; // deploy=10137538


const timelockAddress = null; // TODO
const timelockCreatedBlock = 0; // TODO deploy=

const multisigAddress = null; // TODO
const multisigCreatedBlock = 0; // TODO deploy=


const proxyAdminAddress = '0xa85b51F50b46000467D3Bb84CB224a8e1D9001A4';
const proxyAdminCreatedBlock = 10137263; // deploy=10137263


export default {
  goerliChainId: chainId,
  goerliChainName: chainName,

  goerliUsdtAddress: usdtAddress,
  goerliUsdtCreatedBlock: usdtCreatedBlock,

  goerliWmcAddress: wmcAddress,
  goerliWmcCreatedBlock: wmcCreatedBlock,

  goerliWmcSaleAddress: wmcSaleAddress,
  goerliWmcSaleCreatedBlock: wmcSaleCreatedBlock,

  goerliChallengeIdAddress: challengeIdAddress,
  goerliChallengeIdCreatedBlock: challengeIdCreatedBlock,
  goerliChallengeIdOpenSeaSlug: challengeIdOpenSeaSlug,

  goerliChallengeIdDescriptorAddress: challengeIdDescriptorAddress,
  goerliChallengeIdDescriptorCreatedBlock: challengeIdDescriptorCreatedBlock,

  goerliChallengeAddress: challengeAddress,
  goerliChallengeCreatedBlock: challengeCreatedBlock,

  goerliChallengeDepositorAddress: challengeDepositorAddress,
  goerliChallengeDepositorCreatedBlock: challengeDepositorCreatedBlock,

  goerliTimelockAddress: timelockAddress,
  goerliTimelockCreatedBlock: timelockCreatedBlock,

  goerliMultisigAddress: multisigAddress,
  goerliMultisigCreatedBlock: multisigCreatedBlock,

  goerliProxyAdminAddress: proxyAdminAddress,
  goerliProxyAdminCreatedBlock: proxyAdminCreatedBlock,
};
