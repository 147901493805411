import styles from "./Faq.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Accordeon from "@/components/elements/Accordeon";
import Button from "@/components/common/Button";

import ImageFaq from "@/assets/img/faq/faq.png";
import ImageFaqX2 from "@/assets/img/faq/faq@2x.png";
import ImageFaqGradient from "@/assets/img/faq/gradient.png";
import ImageFaqGradientX2 from "@/assets/img/faq/gradient@2x.png";

import { routerPaths } from "@/router/helper";



export default function FaqSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('Faq');

  const faq = [
    { title: t('Q1'), description: t('A1'), },
    { title: t('Q2'), description: t('A2'), },
    { title: t('Q3'), description: t('A3'), },
    { title: t('Q4'), description: t('A4'), },
    { title: t('Q5'), description: t('A5'), },
    { title: t('Q6'), description: t('A6'), },
  ];

  return (
    <section id="faq" className={styles.faq}>
      <div className={styles.faq__content}>
        <div className={`${styles.faq__relative} container-xxl`}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className={styles.faq__imgbox}>
                <img
                  src={ImageFaq}
                  srcSet={`${ImageFaqX2} 2x`}
                  alt=""
                  width="354"
                  height="472"
                  className={styles.faq__img}
                />
                <img
                  src={ImageFaqGradient}
                  srcSet={`${ImageFaqGradientX2} 2x`}
                  alt=""
                  width="919"
                  height="731"
                  className={styles.faq__shadow}
                />
              </div>
            </div>

            <div className={`col-lg-6 ${styles.faq__main}`}>
              <h2 className={styles.faq__title}>{t('FAQ')}</h2>
              <div className={styles.faq__list}>
                {faq.map((item, idx) => (
                  <Accordeon
                    key={idx}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </div>
              <Button
                className={styles.faq__button}
                onClick={() => navigate(routerPaths.faqPage)}
                text={t('Show all')}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
