import { Contract } from 'ethers';
import { default as C } from '@/constants';
import { default as ABI } from '@/abis';
import getUsdtAddress from './getUsdtAddress';



export default function getUsdtContract(chainId: number, provider: any): Contract {
  let abi = null;
  switch (chainId) {
    case C.bscChainId:
      abi = ABI.BscUsdtAbi;
      break;
    case C.goerliChainId:
      abi = ABI.GoerliUsdtAbi;
      break;
    default:
      throw new Error('Wrong chainId');
  }

  const address = getUsdtAddress(chainId);

  if (provider) {
    return new Contract(address, abi, provider);
  } else {
    return new Contract(address, abi);
  }
}
