export default ({
  apiUrl,
  get,
  post,
}) => {
  const sendSubscribeRequest = ({ email, language, }) => {
    return post({
      url: apiUrl,
      endPoint: '/api/v1/subscribe/request',
      contentType: 'application/json',
      data: {
        email,
        language,
      },
    });
  }

  return {
    sendSubscribeRequest,
  };
}
