import { useState, useEffect } from 'react';
import { Pagination, } from 'semantic-ui-react';
import './TablePagination.scss';




export default function TablePagination({ total, limit, page, onPaginationChange, }) {
  const [state, setState] = useState({
    activePage: parseInt(page) + 1,
    boundaryRange: 1,
    siblingRange: 1,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    totalPages: Math.ceil(parseInt(total) / parseInt(limit)),
  });
  useEffect(() => {
    setState({
      ...state,
      activePage: parseInt(page) + 1,
      totalPages: Math.ceil(parseInt(total) / parseInt(limit)),
    });
  }, [total, limit, page]);

  const handlePaginationChange = (e, { activePage }) => {
    setState({ ...state, activePage });
    if (parseInt(activePage) > 0)
      onPaginationChange(parseInt(activePage) - 1);
    else
      onPaginationChange(0);
  }

  return (
    <Pagination
      floated='right'
      //size='mini'
      activePage={state.activePage}
      boundaryRange={state.boundaryRange}
      onPageChange={handlePaginationChange}
      siblingRange={state.siblingRange}
      totalPages={state.totalPages}
      // Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
      ellipsisItem={state.showEllipsis ? undefined : null}
      firstItem={state.showFirstAndLastNav ? undefined : null}
      lastItem={state.showFirstAndLastNav ? undefined : null}
      prevItem={state.showPreviousAndNextNav ? undefined : null}
      nextItem={state.showPreviousAndNextNav ? undefined : null}
    />
  );
}
