export default ({
  apiUrl,
  get,
  post,
}) => {
  const sendDepositRequest = (value, signature) => {
    return post({
      url: apiUrl,
      endPoint: '/api/v1/deposit/request',
      contentType: 'application/json',
      data: {
        value, signature,
      },
    });
  }


  return {
    sendDepositRequest,
  };
}
