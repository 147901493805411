import * as R from 'ramda';
import * as AT from './actionTypes';



const INITIAL_STATE = {
  authenticated: false,
  id: null,
  guid: null,
  email: null,
  refGuid: null,
  //username: null,
  permissions: [],
  token: null,
  language: null,
};


function profileReducer(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return INITIAL_STATE;
    }

    case AT.REAUTHENTICATE: {
      const xLens = R.lensProp('authenticated');
      const xLens2 = R.lensProp('token');
      let newState = R.set(xLens, false, state);
      newState = R.set(xLens2, null, state);
      return newState;
    }

    case AT.SET_AUTH: {
      localStorage.setItem('refGuid', payload.refGuid);
      return R.merge(state, payload);
    }

    case AT.SET_PARENT: {
      const xLens = R.lensProp('refGuid');
      localStorage.setItem('refGuid', payload);
      return R.set(xLens, payload, state);
    }

    default:
      return state;
  }
}

export default profileReducer;
