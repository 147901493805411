import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';

import {
  useWeb3Modal, useWeb3ModalAccount,
} from '@web3modal/ethers/react';

import styles from "./Starting.module.scss"
import Button from "@/components/common/Button"

import ImageStarting from "@/assets/img/starting/starting.png";
import ImageStartingX2 from "@/assets/img/starting/starting@2x.png";
import ImageStartingGradient from "@/assets/img/starting/gradient.png";
import ImageStartingGradientX2 from "@/assets/img/starting/gradient@2x.png";

import { routerPaths } from "@/router/helper";



export default function Starting() {
  const { t } = useTranslation('Starting');
  const navigate = useNavigate();

  const { open: openLoginModal } = useModal('LoginModal');
  const isAuthenticated = useSelector((state) => S.profile.isAuthenticated(state));

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();

  const onJoinRightNow = async () => {
    if (!isAuthenticated) {
      openLoginModal({}); // TODO то что потом кошелек потом подписка
    } else if (!isConnected) {
      await openWeb3Modal();
    } else {
      navigate(routerPaths.myPage);
    }
  }

  return (
    <section id='how-to-win' className={styles.starting}>
      <div className="container-md">
        <div className="row align-items-center">
          <div
            className={`col-12 col-sm order-1 order-sm-2 order-md-1 ${styles.starting__imgblock}`}
          >
            <div className={styles.starting__imgbox}>
              <img
                src={ImageStarting}
                srcSet={`${ImageStartingX2} 2x`}
                alt="Get started image"
                width="354"
                height="472"
                className={styles.starting__img}
              />
              <img
                src={ImageStartingGradient}
                srcSet={`${ImageStartingGradientX2} 2x`}
                alt="Get started image"
                width="882"
                height="882"
                className={styles.starting__shadow}
              />
            </div>
          </div>
          <div
            className={`order-2 order-sm-1 order-md-2 col-sm-8 col-12 col-md`}
          >
            <h1 className={styles.starting__title}>How to win</h1>
            {/*<p className={styles.starting__desc}>Description</p>*/}
            <div className={styles.starting__list}>
              <div className={styles.starting__item}>
                <span className={styles["starting__item-number"]}>1</span>
                <span className={styles["starting__item-text"]}>Register</span>
              </div>
              <div className={styles.starting__item}>
                <span className={styles["starting__item-number"]}>2</span>
                <span className={styles["starting__item-text"]}>Choose your subscriptions: 3, 6 or 12 month</span>
              </div>
              <div className={styles.starting__item}>
                <span className={styles["starting__item-number"]}>3</span>
                <span className={styles["starting__item-text"]}>Invite friends</span>
              </div>
              <div className={styles.starting__item}>
                <span className={styles["starting__item-number"]}>4</span>
                <span className={styles["starting__item-text"]}>Get a chance to win every day</span>
              </div>
            </div>

            <Button
              className={styles.starting__button}
              text={t('Get started')}
              onClick={onJoinRightNow}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
