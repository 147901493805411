//import { setIn } from 'immutable';
import * as AT from './actionTypes';



const initialState = {
  _id: null,
  chainId: 0,
  currentBlock: 0,
  isUpdating: false,

  challengeStarted: false,
  challengeStartedAt: 0,
  challengeStartedAtDay: 0,
  nextDrawAt: '2024-02-28T20:00:00.000Z',
  participantsForStart: 100000,

  participants: 0,
  referrals: 0,
  noreferrals: 0,
  feesUsdt: '0',
  feesUsdtFloat: 0,
  depositUsdt: '0',
  depositUsdtFloat: 0,
  winAwardedUsdt: '0',
  winAwardedUsdtFloat: 0,
  winClaimedUsdt: '0',
  winClaimedUsdtFloat: 0,
  refAwardedUsdt: '0',
  refAwardedUsdtFloat: 0,
  refClaimedUsdt: '0',
  refClaimedUsdtFloat: 0,
  refMissedUsdt: '0',
  refMissedUsdtFloat: 0,
  //winners: [],
  //referrers: [],
  //referrersMissed: [],
  /*joinedToSeason: {
    '0': 0,
  },*/
  createdAt: 0,
  updatedAt: 0,
  __v: 0,
};


export default function stateReducer(state = initialState, action = {},) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return initialState;
    }

    case AT.SET: {
      return payload;
    }

    default:
      return state;
  }
}
