//import * as R from 'ramda';
import * as AT from './actionTypes';
import { isIncludes } from '../lib';



const INITIAL_STATE = {
  limit: 100,
  list: [],
};


export default function lastEventsReducer(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return INITIAL_STATE;
    }

    case AT.ADD_ONE: {
      if (!isIncludes(state.list, payload)) {
        state.list.unshift(payload);
        state.list.sort((a, b) => {
          return b.eventIndex - a.eventIndex;
        });
        state.list = state.list.slice(0, state.limit);
      }

      return state;
    }

    case AT.ADD_BATCH: {
      if (Array.isArray(payload)) {
        for (let i in payload) {
          if (!isIncludes(state.list, payload[i])) {
            state.list.unshift(payload[i]);
          }
        }

        state.list.sort((a, b) => {
          return b.eventIndex - a.eventIndex;
        });
        state.list = state.list.slice(0, state.limit);
      }

      return state;
    }

    default:
      return state;
  }
}
