import styles from './ModalBody.module.scss';
import ModalWrapper from './ModalWrapper';
import Button from '@/components/common/Button';

import ImageModalsBlue from "@/assets/img/modals/blue.png";
import ImageModalsViolet from "@/assets/img/modals/violet.png";
import ImageModalsRainbow from "@/assets/img/modals/rainbow.png";
import ImageModalsWallet from "@/assets/img/modals/wallet.png";
import ImageModalsWalletX2 from "@/assets/img/modals/wallet@2x.png";
import ImageModalsWin from "@/assets/img/modals/win.png";
import ImageModalsWinX2 from "@/assets/img/modals/win@2x.png";
import ImageModalsWinBg from "@/assets/img/modals/win-bg.png";
import ImageModalsWarning from "@/assets/img/modals/warning.png";
import ImageModalsWarningX2 from "@/assets/img/modals/warning@2x.png";
import ImageModalsCheck from "@/assets/img/modals/check.png";
import ImageModalsCheckX2 from "@/assets/img/modals/check@2x.png";
import ImageModalsStar from "@/assets/img/modals/star.png";
import ImageModalsStarX2 from "@/assets/img/modals/star@2x.png";
import ImageModalsFile from "@/assets/img/modals/file.png";
import ImageModalsFileX2 from "@/assets/img/modals/file@2x.png";
import ImageModalsCard from "@/assets/img/modals/card.png";
import ImageModalsCardX2 from "@/assets/img/modals/card@2x.png";
import ImageModalsTokensDraw from "@/assets/img/modals/tokens-draw.png";
import ImageModalsTokensDrawX2 from "@/assets/img/modals/tokens-draw@2x.png";
import ImageModalsNewNft from "@/assets/img/modals/new-nft.png";
import ImageModalsNewNftX2 from "@/assets/img/modals/new-nft@2x.png";



function getImage(imageName) {
  switch (imageName) {
    case 'blue': return ImageModalsBlue;
    case 'violet': return ImageModalsViolet;
    case 'rainbow': return ImageModalsRainbow;
    case 'wallet': return ImageModalsWallet;
    case 'win': return ImageModalsWin;
    case 'win-bg': return ImageModalsWinBg;
    case 'warning': return ImageModalsWarning;
    case 'check': return ImageModalsCheck;
    case 'star': return ImageModalsStar;
    case 'file': return ImageModalsFile;
    case 'card': return ImageModalsCard;
    case 'tokens-draw': return ImageModalsTokensDraw;
    case 'new-nft': return ImageModalsNewNft;
    default: return '';
  }
}

function getImageX2(imageName) {
  switch (imageName) {
    case 'wallet': return ImageModalsWalletX2;
    case 'win': return ImageModalsWinX2;
    case 'warning': return ImageModalsWarningX2;
    case 'check': return ImageModalsCheckX2;
    case 'star': return ImageModalsStarX2;
    case 'file': return ImageModalsFileX2;
    case 'card': return ImageModalsCardX2;
    case 'tokens-draw': return ImageModalsTokensDrawX2;
    case 'new-nft': return ImageModalsNewNftX2;
    default: return '';
  }
}



export default function ModalBody({
  children,
  isOpen,
  title,
  desc,
  variant,
  img,
  actionName,
  slot,
  closeName,
  className,
  titleAlignment,
  imgSize,
  padding,
  width,
  close,
  action,
}) {
  function CurrentButtons() {
    if (actionName.length && closeName?.length) {
      return (
        <>
          <Button
            className={styles.modal__button}
            variant="outlined"
            text={closeName}
            onClick={close}
          />
          <Button
            className={styles.modal__button}
            text={actionName}
            onClick={action}
          />
        </>
      );
    }

    if (actionName.length && !closeName?.length) {
      return (
        <Button
          className={`${styles.modal__button} ${styles["modal__button--single"]}`}
          text={actionName}
          onClick={action}
        />
      );
    }

    return (
      <Button
        className={`${styles.modal__button} ${styles["modal__button--single"]}`}
        variant="outlined"
        text={closeName}
        onClick={close}
      />
    );
  }


  return (
    <ModalWrapper isOpen={isOpen} close={close} width={width}>
      <div
        className={`${styles.modal} ${className}`}
        style={{ padding: padding ?? "", }}
      >
        <div>
          <img
            src={getImage(variant)}
            alt={variant ? `${variant} background image` : ''}
            title={variant ? `${variant} background image` : ''}
            className={styles.modal__bg}
          />
          <div className={`${styles.modal__top} ${!img ? styles.hidden : styles.modal__top}`}>
            <img
              src={getImage(img)}
              srcSet={`${getImageX2(img)} 2x`}
              alt=""
              className={`${styles.modal__img} ${!img ? styles.hidden : ""}`}
              style={{
                width: imgSize?.width + "px",
                height: imgSize?.height + "px",
              }}
            />
          </div>
          <div className={`${styles.modal__middle} ${styles[titleAlignment]}`}>
            <h4 className={`${styles.modal__title} ${styles[titleAlignment]}`}>
              {title}
            </h4>
            <div className={`${styles.modal__desc} ${!desc ? styles.hidden : ""}`}>
              {desc}
            </div>
          </div>
        </div>

        <div className={`${styles.modal__slot} ${!slot ? styles.hidden : ""}`}>
          {slot}
        </div>

        <div className={styles.modal__bottom}>{<CurrentButtons />}</div>
      </div>
    </ModalWrapper>
  );
};
