import httpService from './http';
import apiAuthorize from './apiAuthorize';
import ethersProvider from './ethersProvider';
import subscribe from './subscribe';
import auth from './auth';
import data from './data';
import deposit from './deposit';



const api = ({
  options,
  apiKey,
  getAuthCredentials,
  reauthenticate,
  dispatch,
}) => {
  const rootUrl = options.domains.root;
  const apiUrl = options.domains.api;
  const http = httpService({ apiKey, });
  const authorizedHttp = apiAuthorize(http, getAuthCredentials, reauthenticate);

  const baseApi = {
    rootUrl,
    apiUrl,
    ...http,
    authorizedGet: authorizedHttp.get,
    authorizedPost: authorizedHttp.post,
    authorizedPut: authorizedHttp.put,
    authorizedPatch: authorizedHttp.patch,
    authorizedDelete: authorizedHttp.deleteRequest,
  };

  return {
    ...http,
    ...authorizedHttp,
    ...ethersProvider(baseApi),
    ...subscribe(baseApi),
    ...auth(baseApi),
    ...deposit(baseApi),
    ...data(baseApi),
  };
}

export default api;
