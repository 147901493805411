import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const addOne = (data) => ({
  type: AT.ADD_ONE,
  payload: data,
});

export const addBatch = (data) => ({
  type: AT.ADD_BATCH,
  payload: data,
});
