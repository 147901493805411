import { put, select } from 'redux-saga/effects';
import * as A from './actions';
//import * as S from './selectors';



const logLocation = 'sagas/lastParticipants/sagas';

export default ({ api, }) => {

  const clearSaga = function* () {
    yield put(A.clear());
  }

  const updateSaga = function* () {
    try {
    } catch (error) {
      yield console.error(logLocation, 'updateSaga()', error);
    }
  }

  return {
    clearSaga,
    updateSaga,
  }
}

