export default ({
  apiUrl,
  get,
  post,
}) => {
  const sendLoginWebCode = ({ email, language, }) => {
    const refGuid = localStorage.getItem('refGuid');
    return post({
      url: apiUrl,
      endPoint: '/api/v1/auth/send-code',
      contentType: 'application/json',
      data: {
        email,
        language,
        refGuid,
      },
    });
  }

  const loginByEmailOtpCode = ({ email, language, code, }) => {
    const refGuid = localStorage.getItem('refGuid');
    return post({
      url: apiUrl,
      endPoint: '/api/v1/auth/confirm-code',
      contentType: 'application/json',
      data: {
        email, language,
        code,
        refGuid,
      },
    });
  }

  const logOut = () => {
    return post({
      url: apiUrl,
      endPoint: '/api/v1/auth/logout',
      contentType: 'application/json',
      data: {},
    });
  }

  return {
    sendLoginWebCode,
    loginByEmailOtpCode,
    logOut,
  };
}
