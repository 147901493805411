import { all, call, fork } from 'redux-saga/effects';
import { appVersion, appName } from '@/config';
import rehydrateRootSaga from './rehydrate/sagaRegister';
import clockRootSaga from './clock/sagaRegister';
import mobileMenuRootSaga from './mobileMenu/sagaRegister';
import profileRootSaga from './profile/sagaRegister';
import providerRootSaga from './provider/sagaRegister';

import stateRootSaga from './state/sagaRegister';
import lastEventsRootSaga from './lastEvents/sagaRegister';
import lastParticipantsRootSaga from './lastParticipants/sagaRegister';



const logLocation = 'sagas/rootSaga';

export default function* rootSaga(fullApi) {
  const sagas = [
    call(welcomeSaga),
    fork(rehydrateRootSaga(fullApi)),
    fork(clockRootSaga(fullApi)),
    fork(mobileMenuRootSaga(fullApi)),
    fork(profileRootSaga(fullApi)),
    fork(providerRootSaga(fullApi)),
    fork(lastEventsRootSaga(fullApi)),
    fork(lastParticipantsRootSaga(fullApi)),
    fork(stateRootSaga(fullApi)),
  ];

  yield all(sagas);
}

function* welcomeSaga() {
  try {
    const style1 = 'background: white; color: black; font-size: 48px;';
    const style2 = 'font-size: 18px;';
    console.log('========================================================================================');
    console.log(`%c Version ${appVersion}`, style2);
    console.log('========================================================================================');
    console.log(`%c ${appName} `, style1);
    console.log('========================================================================================');
    console.log('%c This browser feature is intended for developers.', style2);
    console.log('%c If someone told you to copy-paste something here,', style2);
    console.log('%c it is a scam and will give them access to your money!', style2);
    console.log('========================================================================================');
  } catch (error) {
    console.error(logLocation, 'welcomeSaga()', error);
  }
  yield true;
}
