import styles from "./Table.module.scss"
import Member from "@/assets/img/member.svg?react"
import Deposit from "@/assets/img/deposit.svg?react"
import Win from "@/assets/img/win.svg?react"
import Nft from "@/assets/img/nft.svg?react"
import Tokens from "@/assets/img/tokens.svg?react"
import Button from "@/components/common/Button"
import ArrowRight from "@/assets/img/arrow-right.svg?react"
//import useBreakpoint from 'use-breakpoint';
//import { slicingHash } from "@/utils"
//import { BREAKPOINTS } from "@/app/static"


import { getExplorerTxLink } from "@/hooks";
import { routerPaths } from "@/router/helper";
import { Link, LinkExternal } from "@/components/Link";
import { NumericFormat as Number } from 'react-number-format';
import moment from 'moment';


import { useSelector, useDispatch } from 'react-redux';
import * as S from '@/store/selectors';
import * as A from '@/store/actions';


const eventData = {
  new_participant: { name: 'New participant', },
  challenge_fees_usdt: { name: 'Fees', },
  challenge_deposit_usdt: { name: 'Deposit', },
  challenge_winner_awarded_usdt: { name: 'Winner awarded', },
  challenge_referrer_awarded_usdt: { name: 'Referrer awarded', },
  challenge_winner_claimed_usdt: { name: 'Winner claimed', },
  challenge_referrer_missed_usdt: { name: 'Referrer missed', },
  challenge_referrer_claimed_usdt: { name: 'Referrer claimed', },
  challenge_participant_joined_to_season: { name: 'Joined to Season', },
  challenge_started: { name: 'Challenge started', },
  'undefined': { name: '?', },
};


function TableEventsItem(data: any) {
  //const { breakpoint } = useBreakpoint(BREAKPOINTS);

  function isMaxMd() {
    return true;
    //return breakpoint === "sm" || breakpoint === "xs" || breakpoint === "md";
  }


  function getInfo(name: string, isColor: boolean): string | JSX.Element {
    switch (name) {
      case "new_participant":
        return isColor ? "green" : <Member />
      case "challenge_fees_usdt":
      case "challenge_deposit_usdt":
        return isColor ? "magenta" : <Deposit />
      case "challenge_winner_awarded_usdt":
      case "challenge_winner_claimed_usdt":
        return isColor ? "yellow" : <Win />
      case "challenge_participant_joined_to_season":
        return isColor ? "pink" : <Nft />
      case "challenge_referrer_awarded_usdt":
      case "challenge_referrer_claimed_usdt":
      case "challenge_referrer_missed_usdt":
        return isColor ? "blue" : <Tokens />
      case "challenge_started":
    }
    return "";
  }

  return (
    <div
      //key={data.eventId}
      className={styles.table__item}
      style={{
        gridTemplateColumns:
          isMaxMd()
            ? "1.8fr 3fr 2.8fr"
            : "1fr ".repeat(data.length),
      }}
    >
      <div className={`${styles.table__value}`}>
        <span className={styles.table__button}>
          {!isNaN(data?.accountId)
            ? <Link to={routerPaths.participantPage.replace(':participantId', data?.accountId)}>
              Millionaire ID <Number
                displayType='text' thousandSeparator
                value={data.accountId} prefix='#'
              />
            </Link>
            : ''
          }
        </span>
      </div>

      <div
        className={`${styles.table__value} ${styles[getInfo(data.eventName, true)]}`}
      >
        {getInfo(data.eventName, false)}
        <span>
          {eventData[data.eventName].name} {' '}
          {!isNaN(data.data?.seasonId) && <Number
            displayType='text' thousandSeparator
            value={data.data?.seasonId} prefix='#'
          />}
          {!isNaN(data.data?.amountFloat) && <Number
            displayType='text' thousandSeparator fixedDecimalScale
            decimalScale={data.data?.amountFloat < 1 ? 2 : 0}
            value={data.data?.amountFloat} suffix=' USDT'
          />}
        </span>
      </div>

      <div className={`${styles.table__value}`}>
        <span>{new Date(data.blockTime).toLocaleString()} ({moment(data.blockTime).fromNow()})</span>
      </div>

      <div
        className={styles.table__buttonwrap}
      /*onClick={() => window.open(getExplorerTxLink(
        data?.chainId,
        data?.transactionHash
      ))}*/
      >
        <LinkExternal
          href={getExplorerTxLink(
            data?.chainId,
            data?.transactionHash
          )}
        >
          <Button
            className={styles.table__button}
            variant="text"
            text="More"
          />
        </LinkExternal>
        <ArrowRight />
      </div>
    </div>
  );
}


export default function TableEvents({ titles, data, }: {
  titles: string[]; data: any[],
  //pagination: boolean | undefined
}) {
  //const { breakpoint } = useBreakpoint(BREAKPOINTS);

  function isMaxMd() {
    return true;
    //return breakpoint === "sm" || breakpoint === "xs" || breakpoint === "md";
  }

  return (
    <div className={styles.table}>
      <div
        className={styles.table__header}
        style={{
          gridTemplateColumns:
            isMaxMd()
              ? "1.8fr 3fr 2.8fr"
              : "1fr ".repeat(titles.length),
        }}
      >
        {titles.map((item, index) => {
          return (
            <div key={index} className={styles["table__header-item"]}>
              {item}
            </div>
          );
        })}
      </div>

      <div className={styles.table__content}>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                background: index % 2 !== 0 ? "rgba(255, 255, 255, 0.01)" : "",
              }}
            >
              {TableEventsItem(item)}
            </div>
          )
        })}
      </div>
    </div>
  );
}
