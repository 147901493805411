//import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useModal } from 'react-simple-modal-provider';
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';

import {
  useWeb3Modal, useWeb3ModalAccount,
} from '@web3modal/ethers/react';

import styles from "./About.module.scss"
import { LinkExternal } from "@/components/Link"
import Clocks from "@/components/elements/Clocks"
import Button from "@/components/common/Button"
import Play from "@/assets/img/play.svg?react"
//import Earth from "@/assets/img/earth.png"
import Twitter from "@/assets/img/social/24/twitter.svg?react"
import Telegram from "@/assets/img/social/24/tg.svg?react"
import Medium from "@/assets/img/social/24/medium.svg?react"
import Instagram from "@/assets/img/social/24/instagram.svg?react"

import { NumericFormat as Number } from 'react-number-format';

import {
  twitterLink, telegramLink, instagramLink, mediumLink,
} from "@/config";
import { routerPaths } from "@/router/helper";



export default function About() {
  //const { t } = useTranslation();
  const navigate = useNavigate();

  const { open: openLoginModal } = useModal('LoginModal');
  const isAuthenticated = useSelector((state) => S.profile.isAuthenticated(state));

  const { open: openWeb3Modal, } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();

  const onJoinRightNow = async () => {
    if (!isAuthenticated) {
      openLoginModal({});
    } else if (!isConnected) {
      await openWeb3Modal();
    } else {
      navigate(routerPaths.myPage);
    }
  }


  const challengeState = useSelector((state) => S.state.getState(state));
  const [participantsRest, setParticipantsRest] = useState(challengeState.participantsForStart);
  useEffect(() => {
    const delta = challengeState.participantsForStart - challengeState.participants;
    if (delta < 0) {
      setParticipantsRest(0);
    } else {
      setParticipantsRest(delta);
    }
  }, [challengeState.participants, challengeState.participantsForStart]);

  return (
    <section className={styles.about}>
      <div className={styles.about__content}>
        <div className={`container-xl ${styles.about__wrapper}`}>
          <div className={styles.social}>
            <LinkExternal href={twitterLink} className={styles.social__link}>
              <Twitter />
            </LinkExternal>
            <LinkExternal href={instagramLink} className={styles.social__link}>
              <Instagram />
            </LinkExternal>
            <LinkExternal href={telegramLink} className={styles.social__link}>
              <Telegram width="30" />
            </LinkExternal>
            <LinkExternal href={mediumLink} className={styles.social__link}>
              <Medium />
            </LinkExternal>
          </div>

          <div className="row">
            <div className={`${styles.about__left} col-12 col-sm-6 col-md-8`}>
              <p className={styles.about__desc}>
                Drawing
              </p>
              <h1 className={styles.about__title}>
                <Number
                  displayType='text' thousandSeparator
                  value={1000000} prefix='$'
                />
                <br />every day
              </h1>
              <p className={styles.about__desc}>
                Every day someone will become a millionaire
              </p>
              <div className={styles.about__clock}>
                {challengeState.challengeStarted
                  ? <Number
                    displayType='text' thousandSeparator
                    value={participantsRest} suffix=' participants till start'
                  />
                  : <Clocks />
                }
              </div>
              <Button
                className={styles.about__button}
                text="Join right now!"
                onClick={onJoinRightNow}
              />
            </div>

            <div className={`${styles.about__right} col-12 col-sm-6 col-md-4`}>
              <Play className={styles.about__img} />
              <p className={styles.about__play}>Watch the drawing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
