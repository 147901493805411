import { useState, useEffect, useContext, useCallback } from 'react';

import {
  useWeb3Modal, useWeb3ModalAccount,
} from '@web3modal/ethers/react';

import { useNavigate } from "react-router-dom";
import { routerPaths } from "@/router/helper";

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { useModalState, useModalProps } from 'react-simple-modal-provider';
import ModalBody from './ModalBody';

import Input from '@/components/common/Input';
import Code from '@/components/elements/Code';

import { toast } from 'react-toastify';
import ApiContext from '@/api/api-context';

import * as S from '@/store/selectors';
import * as A from '@/store/actions';



function InputEmail({ onSetEmail }) {
  const { email } = useModalProps('ConfirmOtpCodeModal');

  //const { t } = useTranslation();
  useEffect(() => {
    if (email != '') {
      onSetEmail(email);
    }
  }, [email]);

  return (
    <Input
      className="mb-5"
      id='email' name='email' type='email'
      label="Email" placeholder="Enter email"
      value={email}
      readOnly
    />
  );
}


export default function ConfirmOtpCodeModal({ children }) {
  const [isOpen, setOpen] = useModalState();

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const api = useContext(ApiContext);
  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');


  const [lastSendCodeTime, setLastSendCodeTime] = useState(0);
  const sendCodeInterval = 90 * 1000;
  useEffect(() => {
    setLastSendCodeTime(Date.now());
  }, [isOpen]);


  const timeNowMs = useSelector((state) => S.clock.getTimeMs(state));
  const getResendCodeRestTime = useCallback(() => {
    if (timeNowMs >= (lastSendCodeTime + sendCodeInterval)) {
      return 0;
    }
    return Math.floor((lastSendCodeTime + sendCodeInterval - timeNowMs) / 1000);
  }, [timeNowMs, lastSendCodeTime]);

  const onClose = () => {
    setOpen(false);
    setOtp('');
    setLastSendCodeTime(0);
  }


  const { open: openWeb3Modal, } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();

  const [email, onSetEmail] = useState('');
  const [isProcessing, setProcessing] = useState(false);
  const onAction = async (e) => {
    e.preventDefault();
    if (isProcessing) return;
    if (email === '') return;
    //if (!C.regExpEmail.test(email)) {
    if (!email.includes('@')) {
      toast.warn('Incorrect email.');
      return;
    }

    setProcessing(true);
    try {
      const res = await api.loginByEmailOtpCode({ email, language: i18n.language, code: otp, });
      if (res.ok) {
        // авторизуем интерфейс
        dispatch(A.profile.setAuth(res.result));
        // меняем язык как в базе
        if (i18n.languages.includes(res.result.language)) {
          i18n.changeLanguage(res.result.language);
        }
        onClose();

        if (!isConnected) {
          // подключаем кошелек
          await openWeb3Modal();
        }

        // выдаем покупку подписки
        navigate(routerPaths.myPage);

      } else {
        toast.error('Something went wrong.');
      }
    } catch (error) {
      toast.error('Something went wrong.');
      console.error(error);
    }
    setProcessing(false);
  }

  const onResendCode = async () => {
    if (getResendCodeRestTime() === 0) {

      setProcessing(true);
      try {
        const res = await api.sendLoginWebCode({ email, language: i18n.language, });
        if (res.ok) {
          toast.success('The code has been resent.');
          setLastSendCodeTime(Date.now());
        } else {
          toast.error('Something went wrong.');
        }
      } catch (error) {
        toast.error('Something went wrong.');
        console.error(error);
      }
      setProcessing(false);
    }
  }

  return (
    <Modal
      id={'ConfirmOtpCodeModal'}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      //asyncOpen={asyncOpen}
      draggable={false}
      duration={300}
    //width={520}
    //height={320}
    //radius={10}
    //backgroundColor={'rgb(254, 92, 80)'}
    //animation={modalAnimation.scaleUp}
    >
      <ModalBody
        isOpen={isOpen}
        title="Enter OTP code"
        actionName="Confirm"
        width="520"
        closeName="Cancel"
        titleAlignment="left"
        action={onAction}
        close={onClose}
        slot={
          <>
            <InputEmail onSetEmail={onSetEmail} />
            <Code
              otp={otp} setOtp={setOtp}
              onResendCode={onResendCode}
              resendCodeRestTime={getResendCodeRestTime()}
            />
          </>
        }
      />
    </Modal>
  );
};
