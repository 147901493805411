import { useEffect } from 'react';
import { useLocation, } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Events as ScrollEvents, } from 'react-scroll';
//import * as A from '../../store/actions';



export default function ScrollToTopOnMount() {
  const dispatch = useDispatch();
  const { pathname, hash, key, } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      //dispatch(A.mobileMenu.set(false));
      window.scrollTo(0, 0);
    }

    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          //dispatch(A.mobileMenu.set(false));
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


  // Закрытие меню мобильной версии
  useEffect(() => {
    ScrollEvents.scrollEvent.register('begin', (to, element) => {
      //dispatch(A.mobileMenu.set(false));
    });
    return () => {
      ScrollEvents.scrollEvent.remove('begin');
    };
  }, []);


  return (null);
}
