import styles from './ModalWrapper.module.scss';
import { useRef, useEffect } from 'react';
import noScrollFix from './lib/noScrollFix';
import useOutsideClickAction from './lib/useOutsideClickAction';



export default function ModalWrapper({
  children, isOpen, close, width,
}) {
  useEffect(() => noScrollFix(isOpen), [isOpen]);

  const wrapperRef = useRef(null);
  useOutsideClickAction(wrapperRef, close);

  return (
    <div className={`${styles.modal} ${styles["modal--active"]}`} >
      <div
        className={styles.modal__content} style={{ width: width + "px" }}
        ref={wrapperRef}
      >
        {children}
      </div>
    </div>
  );
};
