import { createContext } from 'react';
import { apiKey, rootDomain, apiDomain } from '@/config';
import createApi from '@/api';



const options = {
  domains: { root: rootDomain, api: apiDomain, },
};
const apiOptions = { apiKey, options, };
export let api = createApi({ ...apiOptions, });


const ApiContext = createContext(api);
export default ApiContext;
