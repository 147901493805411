import { Contract } from 'ethers';
import { default as C } from '@/constants';
import { default as ABI } from '@/abis';
import getChallengeAddress from './getChallengeAddress';



export default function getChallengeContract(chainId: number, provider: any): Contract {
  let abi = null;
  switch (chainId) {
    case C.bscChainId:
      abi = ABI.BscChallengeAbi;
      break;
    case C.goerliChainId:
      abi = ABI.GoerliChallengeAbi;
      break;
    default:
      throw new Error('Wrong chainId');
  }

  const address = getChallengeAddress(chainId);

  if (provider) {
    return new Contract(address, abi, provider);
  } else {
    return new Contract(address, abi);
  }
}
