import styles from "./Button.module.scss"
import React from "react"

interface Button {
  text?: string
  disabled?: boolean
  variant?: "outlined" | "default" | "text"
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  icon?: JSX.Element
  style?: object
}

function Button({ onClick, className, disabled, variant, text, icon, ...props }: Button) {
  return (
    <button
      onClick={onClick}
      className={`${className} ${styles.button} ${styles[variant]}
      ${disabled ? styles.disabled : ""}`}
      {...props}
    >
      {text}
      <div
        className={styles.button__icon}
        style={{ display: !icon ? "none" : "flex" }}
      >
        {icon}
      </div>
    </button>
  )
}

export default Button
