import { FC } from 'react';
import Link from './Link';
import { LinkProps } from './types';
//import OpenNewIcon from '../Svg/Icons/OpenNew';



const LinkExternal: FC<LinkProps> = ({ children, ...props }) => {
  return (
    <Link external {...props}>
      {children}
      {/*<OpenNewIcon color='primary' ml='4px' />*/}
    </Link>
  );
};

export default LinkExternal;
