import { default as C } from '@/constants';



export default function getChallengeDepositorAddress(chainId: number): string {
  switch (chainId) {
    case C.bscChainId:
      return C.bscChallengeDepositorAddress;
    case C.goerliChainId:
      return C.goerliChallengeDepositorAddress;
    default:
      throw new Error('Wrong chainId');
  }
}
