import styles from "./Events.module.scss"
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';

import Button from '@/components/common/Button';
import TableEvents from '@/components/elements/TableEvents';
import TableEventsAdaptive from '@/components/elements/TableEventsAdaptive';

import { routerPaths } from "@/router/helper";



const limit = 10;

export default function Events() {
  const { t } = useTranslation('Events');
  const navigate = useNavigate();

  const dataList = useSelector((state) => S.lastEvents.getListWithLimit(state, limit));

  const titles = ['ID', 'Event', 'Time'];

  return (
    <section className={styles.events}>
      <div className="container-lg">
        <h2 className={styles.events__title}>Events</h2>
        <div className={styles["events__table--lg"]}>
          <TableEvents titles={titles} data={dataList} />
        </div>
        <div className={styles["events__table--sm"]}>
          <TableEventsAdaptive data={dataList} />
        </div>

        <div className={styles.events__bottom}>
          <Button
            className={styles.events__button}
            onClick={() => navigate(routerPaths.eventsPage)}
            text="Show all"
          />
        </div>
      </div>
    </section>
  );
}
