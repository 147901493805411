import { fork, take, call } from 'redux-saga/effects';



export const takeFirst = (pattern, saga, ...args) => fork(function* () {
  while (true) {
    const action = yield take(pattern);
    yield call(saga, ...args.concat(action));
  }
});

export const newObj = (obj) => {
  return JSON.parse(JSON.stringify((obj)));
}

export const isIncludes = (list = [], obj = {}) => {
  let found = false;

  for (let i in list) {
    if (list[i]._id === obj._id) {
      found = true;
    }
  }

  return found;
}
