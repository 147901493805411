export const routerPaths = {
  homePage: '/',

  eventsPage: '/events',
  participantsPage: '/participants',
  participantPage: '/participant/:participantId',

  myPage: '/my',
  usersPage: '/users',
  userPage: '/user/:userId',

  idsPage: '/ids', //TODO ? NFTs

  faqPage: '/faq',
  termsPage: '/terms',
  privacyPage: '/privacy',

  unsubscribePage: '/unsubscribe',
};
