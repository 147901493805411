import { defaultChainId } from '@/config';



export default ({
  apiUrl,
  get,
  post,
}) => {
  const getState = () => {
    return get({
      url: apiUrl,
      endPoint: '/api/v1/state',
      contentType: 'application/json',
      data: {
        chainId: defaultChainId,
      },
    });
  }

  const getEvents = ({ limit = 10, page = 0, }) => {
    return get({
      url: apiUrl,
      endPoint: '/api/v1/events',
      contentType: 'application/json',
      data: {
        chainId: defaultChainId,
        limit,
        page,
      },
    });
  }

  const getParticipants = ({ limit = 10, page = 0, }) => {
    return get({
      url: apiUrl,
      endPoint: '/api/v1/participants',
      contentType: 'application/json',
      data: {
        chainId: defaultChainId,
        limit,
        page,
      },
    });
  }

  return {
    getState,
    getEvents,
    getParticipants,
  };
}
