import styles from "./Footer.module.scss"
import { useState, useContext, useCallback, } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ApiContext from '@/api/api-context';

import { routerPaths } from "@/router/helper";
import {
  twitterLink, telegramLink, discordLink,
  whitepaperLink,
  wmcTokenLinkExplorer, wmcTokenLinkCoinMarketCap, wmcTokenLinkCoinGecko, wmcTokenSecurityAuditLink,
  wmcChallengeLinkExplorer, wmcChallengeSecurityAuditLink, wmcIdLinkOpenSea,
  gitHubLink,
} from "@/config";
import { default as C } from '@/constants';

import { Link, LinkExternal } from "@/components/Link";
import Badge from "@/components/common/Badge"
import Input from "@/components/common/Input"
import Button from "@/components/common/Button"
import CapCoin from "@/assets/img/footer/coin.svg?react"
import DinoCoin from "@/assets/img/footer/dino.svg?react"
import Twitter from "@/assets/img/social/twitter.svg?react"
import Telegram from "@/assets/img/social/tg.svg?react"
import Discord from "@/assets/img/social/discord.svg?react"



function SubForm() {
  const { i18n, t } = useTranslation('FooterSubscribeForm');
  const api = useContext(ApiContext);

  const [email, setEmail] = useState('');
  const onChangeEmail = (e) => {
    setEmail(e.target.value.trim().toLowerCase());
  }

  const [isProcessing, setProcessing] = useState(false);
  const onSubmitFn = async (e) => {
    e.preventDefault();
    if (isProcessing) return;
    if (email === '') return;
    //if (!C.regExpEmail.test(email)) {
    if (!email.includes('@')) {
      toast.warn('Incorrect email.');
      return;
    }

    setProcessing(true);
    try {
      const res = await api.sendSubscribeRequest({ email, language: i18n.language, });
      if (res.ok) {
        toast.success('You are subscribed.');
      } else {
        toast.error('Something went wrong.');
      }
    } catch (error) {
      toast.error('Something went wrong.');
      console.error(error);
    }

    setEmail('');
    setProcessing(false);
  }
  const onSubmit = useCallback(onSubmitFn, [email]);


  return (
    <form className={styles.subscribe}>
      <div className={styles.subscribe__top}>
        <h3 className={styles.subscribe__title}>{t('Name')}</h3>
        <div className={styles.subscribe__social}>
          <LinkExternal href={twitterLink}>
            <Twitter />
          </LinkExternal>
          <LinkExternal href={telegramLink}>
            <Telegram />
          </LinkExternal>
          <LinkExternal href={discordLink}>
            <Discord />
          </LinkExternal>
        </div>
      </div>
      <p className={styles.subscribe__text}>
        {t('Description')}
      </p>
      <div className={styles.subscribe__bottom}>
        <Input
          required={true}
          id='email' name='email' type='text'
          pattern={C.regExpEmail}
          placeholder={t('InputPlaceholder')}
          value={email}
          onChange={onChangeEmail}
          readOnly={isProcessing}
        />
        <Button
          className={styles.subscribe__button}
          text={t('ButtonSubmit')}
          onClick={onSubmit}
          disabled={isProcessing}
        />
      </div>
    </form>
  );
}

export default function Footer() {
  const { t } = useTranslation('Footer');

  return (
    <footer className={styles.footer}>
      <div className="container-xl">
        <div className="row justify-content-between">
          <div className="col-5 col-md-3 col-xl-2">
            <div className={styles.footer__nav}>
              <h3 className={styles["footer__nav-title"]}>Company</h3>
              <LinkExternal href={whitepaperLink} className={styles["footer__nav-item"]}>
                {t('Whitepaper')}
              </LinkExternal>
              <Link to={'/#roadmap'} className={styles["footer__nav-item"]}>
                {t('Roadmap')}
              </Link>
              <Link to={'/#tokenomics'} className={styles["footer__nav-item"]}>
                {t('Tokenomics')}
              </Link>
              <LinkExternal href={gitHubLink} className={styles["footer__nav-item"]}>
                GitHub
              </LinkExternal>
              {/*<Link to="/" className={styles["footer__nav-item"]}>
                Team
              </Link>
              <Link to="/" className={styles["footer__nav-item"]}>
                Contacts
              </Link>*/}
            </div>
          </div>

          <div className="col-7 col-md-4 col-xl-2">
            <div className={styles.footer__nav}>
              <h3 className={styles["footer__nav-title"]}>{t('Challenge')}</h3>
              <LinkExternal href={wmcChallengeLinkExplorer} className={styles["footer__nav-item"]}>
                {t('Challenge Smart Contract')}
              </LinkExternal>
              <LinkExternal href={wmcIdLinkOpenSea} className={styles["footer__nav-item"]}>
                {t('Millionaire ID')}
              </LinkExternal>
              <LinkExternal href={wmcChallengeSecurityAuditLink} className={styles["footer__nav-item"]}>
                {t('Security audit')} <Badge text={t('Soon')} />
              </LinkExternal>
              <Link to={routerPaths.eventsPage} className={styles["footer__nav-item"]}>
                {t('Challenge Events')}
              </Link>
              <Link to={routerPaths.participantsPage} className={styles["footer__nav-item"]}>
                {t('Challenge Participants')}
              </Link>
              <Link to={routerPaths.faqPage} className={styles["footer__nav-item"]}>
                {t('FAQ')}
              </Link>
            </div>
          </div>

          <div className={`${styles.footer__fixmb} col-12 col-md-4 col-xl-3`}>
            <div className={styles.footer__nav}>
              <h3 className={styles["footer__nav-title"]}>{t('WMC Token')}</h3>
              <LinkExternal href={wmcTokenLinkExplorer} className={styles["footer__nav-item"]}>
                {t('WMC Token Smart Contract')}
              </LinkExternal>
              <LinkExternal href={wmcTokenSecurityAuditLink} className={styles["footer__nav-item"]}>
                {t('Security audit')} <Badge text={t('Soon')} />
              </LinkExternal>
              <LinkExternal href={wmcTokenLinkCoinMarketCap} className={styles["footer__nav-item"]}>
                <CapCoin /> {t('CoinMarketCap')} <Badge text={t('Soon')} />
              </LinkExternal>
              <LinkExternal href={wmcTokenLinkCoinGecko} className={styles["footer__nav-item"]}>
                <DinoCoin /> {t('CoinGecko')} <Badge text={t('Soon')} />
              </LinkExternal>
            </div>
          </div>

          <div className="col-12 col-md-7 col-xl-5">
            <SubForm />
          </div>

          <div className="col-12 col-md-4 col-xl-12">
            <div className={styles.footer__bottom}>
              <div className="row  align-items-center justify-content-between">
                <div className="col-12 col-xl-7 order-2 order-md-1">
                  <p className={styles.footer__copyright}>
                    {
                      new Date().getFullYear() === 2023
                        ? t('Copyright', { years: `${new Date().getFullYear()}`, })
                        : t('Copyright', { years: `2023-${new Date().getFullYear()}`, })
                    }
                  </p>
                </div>
                <div className="col-12 col-lg-auto col-xl-auto order-1 order-md-2">
                  <div className={styles.footer__links}>
                    <Link to={routerPaths.termsPage} className={styles.footer__link}>
                      {t('Terms')}
                    </Link>
                    <Link to={routerPaths.privacyPage} className={styles.footer__link}>
                      {t('Privacy')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div
          className="row mt-32"
          style={{
            display: 'block',
            marginTop: '25px',
          }}
        >
          <div>
            <a>
              <img
                onClick={() => window?.logBadgeClick()}
                id="badge-button"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '240px', height: '53px',
                }}
                src="https://static.alchemyapi.io/images/marketing/badge.png"
                alt="Alchemy Supercharged"
              />
            </a>
          </div>
        </div>*/}
      </div>
    </footer>
  );
}
