import styles from "./Filter.module.scss"
import SettingsIcon from "@/assets/img/settings.svg?react"
import CheckIcon from "@/assets/img/check.svg?react"
import CloseIcon from "@/assets/img/close-filter.svg?react"
import { useState } from "react"

function FilterItem({ title }: { title: string }) {
  const [isActive, setActive] = useState(false)

  return (
    <div
      onClick={() => setActive(!isActive)}
      className={`${styles.filter__item} ${
        isActive ? styles["filter__item--active"] : ""
      }`}
    >
      <div className={styles["filter__item-check"]}>
        <CheckIcon />
      </div>
      <p className={styles["filter__item-text"]}>{title}</p>
    </div>
  )
}

function CurrentIcon({ isActive }: { isActive: boolean }) {
  if (isActive) {
    return <CloseIcon />
  }
  return <SettingsIcon />
}

function Filter() {
  const [isActive, setActive] = useState(false)

  const data = [
    ["A new prize draw"],
    ["A new tokens draw"],
    ["A new NFT draw"],
    ["A new kind of NFT has been released"],
    ["Got a project NFT"],
    ["Got a win $100.000"],
  ]

  return (
    <div className={styles.filter}>
      <button
        className={styles.filter__button}
        onClick={() => setActive(!isActive)}
      >
        Filters
        <CurrentIcon isActive={isActive} />
      </button>
      <div
        className={`${styles.filter__content} ${
          isActive ? styles["filter__content--active"] : ""
        }`}
      >
        <p className={styles.filter__title}>All</p>
        <div className={styles.filter__list}>
          {data.map((item, key) => {
            return <FilterItem key={key} title={item} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Filter
