import { getIn } from 'immutable';
import * as R from 'ramda';



const root = 'profile';
export const isAuthenticated = (state) =>
  getIn(state, [root, 'authenticated'], false);

export const getToken = (state) =>
  getIn(state, [root, 'token'], null);
/*export const getUsername = (state) =>
  getIn(state, [root, 'username'], null);*/
export const getEmail = (state) =>
  getIn(state, [root, 'email'], null);
export const getRefId = (state) =>
  getIn(state, [root, 'guid'], null);
export const getReferrerId = (state) =>
  R.pathOr(0, ['profile', 'refGuid'], state);


export const getAuthCredentials = (state) => {
  const token = getToken(state);
  //const username = getUsername(state);
  const email = getEmail(state);
  return { token, email, };
}
