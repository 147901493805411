import styles from "./EventsPage.module.scss"

import { useState, useEffect, useContext, useCallback } from 'react';
import ApiContext from '@/api/api-context';
//import { Segment, Dimmer, Loader, } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import ArriowIcon from "@/assets/img/arrow-left.svg?react"
import { Link } from "react-router-dom"
import { routerPaths } from "@/router/helper";

import TableEvents from "@/components/elements/TableEvents"
import TableEventsAdaptive from "@/components/elements/TableEventsAdaptive"
import TablePagination from '@/components/elements/TablePagination';
import Filter from "@/components/elements/Filter"

import ImageParticipantsBg1 from "@/assets/img/participants/participants-bg-1.png";
import ImageParticipantsBg1X2 from "@/assets/img/participants/participants-bg-1@2x.png";
import ImageParticipantsBg2 from "@/assets/img/participants/participants-bg-2.png";
import ImageParticipantsBg2X2 from "@/assets/img/participants/participants-bg-2@2x.png";

import DefaultLayout from "@/layouts/DefaultLayout";

import { useSelector } from 'react-redux';
import * as S from '@/store/selectors';





export default function EventsPage() {
  const api: any = useContext(ApiContext);

  const titles = ['ID', 'Event', 'Time'];
  //const dataList = useSelector((state) => S.lastEvents.getListWithLimit(state, limit));

  const [state, setState] = useState({ total: 0, limit: 100, page: 0, list: [] });
  const [isDimmed, setDimmed] = useState(false);

  const fetchData = useCallback(async (activePage: number) => {
    setDimmed(true);
    try {
      const res = await api.getEvents({
        limit: state.limit,
        page: activePage !== state.page ? activePage : state.page,
      });
      if (res.ok) {
        setState(res.result);
      } else { toast.error(res.error.message); }
    } catch (error) {
      //toast.error(error.error ? error.error.message : error); 
    }
    setDimmed(false);

    try {
      window.scrollTo(0, 0);
    } catch (error) { }
  }, [state]);

  useEffect(() => {
    fetchData(0);
  }, []);

  const handlePaginationChange = (activePage: number) => {
    fetchData(activePage);
  };

  return (
    <DefaultLayout title={'Challenge Events'}>
      <div className={styles.main}>
        <div className={styles.events}>
          <img
            src={ImageParticipantsBg1}
            srcSet={`${ImageParticipantsBg1X2} 2x`}
            alt="WMC.PRO Events Page Background Image 1"
            title="WMC.PRO Events Page Background Image 1"
            className={styles.events__bg1}
          />
          <img
            src={ImageParticipantsBg2}
            srcSet={`${ImageParticipantsBg2X2} 2x`}
            alt="WMC.PRO Events Page Background Image 2"
            title="WMC.PRO Events Page Background Image 2"
            className={styles.events__bg2}
          />

          <div className={`${styles.events__relative} container-xl`}>
            <Link to={routerPaths.homePage} className={styles.events__back}>
              <ArriowIcon />
              Back
            </Link>

            <div className={styles.events__top}>
              <h1 className={styles.events__title}>Events</h1>
              {/*<Filter />*/}
              <TablePagination
                total={state.total}
                limit={state.limit}
                page={state.page}
                onPaginationChange={handlePaginationChange}
              />
            </div>
            <div className={styles["events__table--lg"]}>
              <TableEvents titles={titles} data={state.list} />
            </div>
            <div className={styles["events__table--sm"]}>
              <TableEventsAdaptive data={state.list} />
            </div>
            <TablePagination
              total={state.total}
              limit={state.limit}
              page={state.page}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
