//import { takeLatest } from 'redux-saga/effects';
import { takeFirst } from '../lib';
import * as AT from './actionTypes';
import sagas from './sagas';



export default (/*{ api,}*/) => {
  const {
    clearSaga,
    updateSaga,
  } = sagas({
    //api,
  });

  return function* providerRootSaga() {
    yield takeFirst(AT.CLEAR, clearSaga);
    yield takeFirst(AT.UPDATE, updateSaga);
  }
}
