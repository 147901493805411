import styles from "./Modals.module.scss"

import DefaultModal from "@/components/modals/Default"
import Input from "@/components/common/Input"
import Clocks from "@/components/elements/Clocks"
import TonIcon from "@/assets/img/ton.svg?react"
import Badge from "@/components/common/Badge"
import Button from "@/components/common/Button"
import Code from "@/components/elements/Code"
import useBreakpoint from "use-breakpoint"
import { BREAKPOINTS } from "@/app/static"

import ImageDaysGradient from "@/assets/img/days/gradient.png";
import ImageDaysGradientX2 from "@/assets/img/days/gradient@2x.png";



function ModalBuyNft({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  return (
    <DefaultModal
      title="#4687"
      width="520"
      desc={
        <p>
          Are you sure you want buy <br /> this NFT for $39?
        </p>
      }
      img="card"
      actionName="Buy now"
      variant="violet"
      closeName="Cancel"
      isActive={isActive}
      imgSize={{ width: 220 }}
      padding={
        breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 6.8rem 4rem 6.8rem"
      }
      close={close}
      action={action}
    />
  )
}

function ModalBuyToken({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  return (
    <DefaultModal
      width="520"
      desc={
        <p>
          Are you sure you want buy <br /> this Token for $39?
        </p>
      }
      img="card"
      actionName="Buy now"
      variant="blue"
      closeName="Cancel"
      imgSize={{ width: 220 }}
      padding={
        breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 6.8rem 4rem 6.8rem"
      }
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalSubscription({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  return (
    <DefaultModal
      title="Subscription"
      desc="Do you want to purchase a 6-month subscription for $186?"
      img="star"
      width="520"
      padding={
        breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 6.8rem 4rem 6.8rem"
      }
      actionName="Buy"
      variant="rainbow"
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalSubscriptionRenew({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  return (
    <DefaultModal
      title="Subscription"
      desc={
        <p>
          Renewing the subscription for <br /> another 3 months for $90?
        </p>
      }
      img="star"
      width="520"
      padding={
        breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 6.8rem 4rem 6.8rem"
      }
      actionName="Extend"
      variant="rainbow"
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalTopUpBalance({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="Top up the balance"
      actionName="Confirm"
      width="535"
      closeName="Cancel"
      titleAlignment="left"
      isActive={isActive}
      action={action}
      close={close}
      slot={
        <>
          <div>
            <div className={styles.balance}>
              <p className={styles.balance__title}>Your balance:</p>
              <Badge
                text={
                  <p className={styles.balance__price}>
                    <TonIcon />3 USDT
                  </p>
                }
              />
            </div>
            <Input label="Amount, USDT" icon={<TonIcon />} />
          </div>
        </>
      }
    />
  )
}

function ModalGotAWin({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  return (
    <DefaultModal
      title="Got a win"
      desc="$100.000"
      img="win"
      width="520"
      padding={breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 7.5rem"}
      actionName=""
      variant="win-bg"
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
      slot={
        <Input
          label="Winner"
          value="0xe23ad4e00d2fb9827761f4a3a0958191ab22721f"
          readOnly={true}
        />
      }
    />
  )
}

function ModalNewPrizeDraw({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  return (
    <DefaultModal
      title="A new prize draw"
      desc="1,000,000 WMC"
      width="520"
      img="win"
      actionName="Join right now"
      variant="win-bg"
      closeName="Cancel"
      padding={breakpoint === "xs" ? "3.2rem 1rem" : "4rem"}
      isActive={isActive}
      close={close}
      action={action}
      slot={
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Clocks />
        </div>
      }
    />
  )
}

function ModalProjectNFT({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  return (
    <DefaultModal
      title="Got a project NFT"
      img="new-nft"
      actionName="Learn more"
      variant="violet"
      width="545"
      imgSize={{ width: 220 }}
      closeName="Cancel"
      padding={breakpoint === "xs" ? "3.2rem 1.6rem" : "4rem 5.2rem"}
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalTokensDraw({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="A new tokens draw"
      img="tokens-draw"
      actionName="Buy now"
      variant="blue"
      width="545"
      imgSize={{ width: 190 }}
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalNFTDraw({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  return (
    <DefaultModal
      title="A new NFT draw"
      img="new-nft"
      actionName="Buy now"
      variant="violet"
      width="545"
      padding={breakpoint === "xs" ? "3.2rem 2.4rem" : "4rem 7.5rem"}
      imgSize={{ width: 220 }}
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalNewRelease({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  return (
    <DefaultModal
      title="A new kind of NFT has been released"
      img="new-nft"
      actionName="Learn more"
      width="535"
      imgSize={{ width: 220 }}
      variant="blue"
      padding={breakpoint === "xs" ? "3.2rem 1.5rem" : "4rem"}
      closeName="Cancel"
      isActive={isActive}
      close={close}
      action={action}
    />
  )
}

function ModalYourSub({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="Your subscription"
      desc="is issued from 24.08.23 to 24.11.23"
      img="file"
      actionName="Got it"
      width="540"
      isActive={isActive}
      action={action}
    />
  )
}

function ModalEndSubscription({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="There are 10 days"
      desc="left until the end of the subscription"
      img="warning"
      width="520"
      actionName="Go to home page"
      isActive={isActive}
      action={action}
      slot={
        <div className={styles.ticket}>
          <div className={styles.ticket__item}>
            <img
              src={ImageDaysGradient}
              srcSet={`${ImageDaysGradientX2} 2x`}
              alt=""
              className={styles.ticket__shadow}
            />
            <div className={styles.ticket__top}>
              <p className={styles.ticket__title}>3 month</p>
              <Badge text="$90" />
            </div>
            <Button
              className={styles.ticket__button}
              variant="outlined"
              text="Extend"
            />
          </div>
          <div className={styles.ticket__item}>
            <img
              src={ImageDaysGradient}
              srcSet={`${ImageDaysGradientX2} 2x`}
              alt=""
              className={styles.ticket__shadow}
            />
            <div className={styles.ticket__top}>
              <p className={styles.ticket__title}>6 month</p>
              <Badge text="$186" />
            </div>
            <Button
              className={styles.ticket__button}
              variant="outlined"
              text="Buy"
            />
          </div>
          <div className={styles.ticket__item}>
            <img
              src={ImageDaysGradient}
              srcSet={`${ImageDaysGradientX2} 2x`}
              alt=""
              className={styles.ticket__shadow}
            />
            <div className={styles.ticket__top}>
              <p className={styles.ticket__title}>12 month</p>
              <Badge text="$366" />
            </div>
            <Button
              className={styles.ticket__button}
              variant="outlined"
              text="Buy"
            />
          </div>
        </div>
      }
    />
  )
}

function ModalWithdrawal({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="Token withdrawal completed"
      desc="1,000,000 WMC"
      img="check"
      width="520"
      actionName="Go to home page"
      isActive={isActive}
      action={action}
      slot={
        <Input
          label="Address"
          isCopy={true}
          value="0xe23ad4e00d2fb9827761f4a3a0958191ab22721f"
          readOnly={true}
        />
      }
    />
  )
}

function ModalBalance({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="Top balance"
      width="520"
      desc={
        <p className={styles.top}>
          The balance is topped up with{" "}
          <span>
            <TonIcon /> 90 USDT
          </span>
        </p>
      }
      img="wallet"
      actionName="Go to home page"
      isActive={isActive}
      action={action}
      slot={<Input label="Transaction link" isCopy={true} readOnly={true} />}
    />
  )
}

function ModalSignIn({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="Sign in"
      width="520"
      actionName="Send code"
      closeName="Cancel"
      titleAlignment="left"
      isActive={isActive}
      action={action}
      close={close}
      slot={<Input label="Email" placeholder="Enter email" />}
    />
  )
}

function ModalOTP({
  close,
  action,
  isActive,
}: {
  isActive: boolean
  close?: () => void
  action?: () => void
}) {
  return (
    <DefaultModal
      title="The OTP Code"
      actionName="Confirm"
      width="520"
      closeName="Cancel"
      titleAlignment="left"
      isActive={isActive}
      action={action}
      close={close}
      slot={
        <>
          <Input className="mb-5" label="Email" placeholder="Enter email" />
          <Code />
        </>
      }
    />
  )
}

export {
  ModalBuyNft,
  ModalBuyToken,
  ModalSubscription,
  ModalSubscriptionRenew,
  ModalTopUpBalance,
  ModalGotAWin,
  ModalNewPrizeDraw,
  ModalProjectNFT,
  ModalTokensDraw,
  ModalNFTDraw,
  ModalNewRelease,
  ModalYourSub,
  ModalEndSubscription,
  ModalWithdrawal,
  ModalBalance,
  ModalSignIn,
  ModalOTP,
}
