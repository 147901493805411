import { useState } from "react";
import styles from "./Input.module.scss";
import CopyIcon from "@/assets/img/copy.svg?react";

function Input(props: {
  id?: string
  type?: string
  pattern?: string | RegExp
  name?: string
  required?: boolean
  value: string
  label?: string
  placeholder?: string
  changeble?: boolean
  isCopy?: boolean
  onCopy?: React.MouseEventHandler<HTMLButtonElement>
  readOnly?: boolean
  className?: string
  icon?: JSX.Element
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}) {
  const [isDisabled, setDisabled] = useState(props.changeble)

  return (
    <div className={`${styles.label} ${props.className}`}>
      <p className={`${styles.label__title} ${props.label ? "" : "d-none"}`}>
        {props.label}
      </p>
      <div className={styles.label__wrapper}>
        <input
          type={props.type}
          className={
            `${styles.input} ${isDisabled ? styles.disabled : ""
            } ${props.isCopy || props.icon ? styles["input--icon"] : ""
            }`
          }
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
        />
        <p
          className={`${styles.label__change} ${!isDisabled ? styles.hidden : ""
            }`}
          onClick={() => setDisabled(false)}
        >
          Change
        </p>

        <div className={styles.label__actions}>
          <div className={props.icon ? styles.label__icon : styles.hidden}>
            {props.icon}
          </div>
          <button
            className={props.isCopy ? styles.label__copy : styles.hidden}
            onClick={props.onCopy}
          >
            <CopyIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Input;
