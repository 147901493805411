import styles from "./Tokens.module.scss"
import Filter from "@/components/elements/Filter"

import ImageLogoCompressed from "@/assets/img/main/logo-compressed.svg";
import ImageParticipantsBg1 from "@/assets/img/participants/participants-bg-1.png";
import ImageParticipantsBg1X2 from "@/assets/img/participants/participants-bg-1@2x.png";

import DefaultLayout from "@/layouts/DefaultLayout";



export default function Tokens() {
  return (
    <DefaultLayout title='Participants'>
      <div className={styles.main}>
        <img
          src={ImageParticipantsBg1}
          srcSet={`${ImageParticipantsBg1X2} 2x`}
          alt="MILLIONAIRE IDs image"
          title="MILLIONAIRE IDs image"
          className={styles.tokens__bg1}
        />
        <div className={styles.tokens}>
          <div className={`${styles.tokens__relative} container-xl`}>
            <div className={styles.tokens__top}>
              <h1 className={styles.tokens__title}>MILLIONAIRE IDs</h1>
              <Filter />
            </div>
            <div className={styles.tokens__content}>
              {[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20,
              ].map((item) => {
                return (
                  <div className={styles.tokens__box}>
                    <div className={styles.tokens__inner}>
                      <img
                        key={item}
                        src={ImageLogoCompressed}
                        alt="WMC ID image"
                        className={styles.tokens__item}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
