import styles from './Main.module.scss';

import DefaultLayout from '@/layouts/DefaultLayout';
import About from '@/components/sections/About';
import Events from '@/components/sections/Events';
import Tokenomics from '@/components/sections/Tokenomics';
import Faq from '@/components/sections/Faq';
import Starting from '@/components/sections/Starting';
import Roadmap from '@/components/sections/Roadmap';
//import Participants from '@/components/sections/Participants';



export default function MainPage() {
  return (
    <DefaultLayout>
      <About />

      <div style={{
        textAlign: 'center',
      }}>
        <div>1. $1 is deducted from the participant's account every day</div>
        <div>2. Every day someone gets 90% of this money, and 10% will be distributed through the referral system</div>
        <div>3. The more participants there are, the higher daily prize</div>
      </div>

      <Starting />

      <div style={{
        textAlign: 'center',
      }}>
        <div id='referral-system'><h2>More friends - more chances</h2></div>
        <div>A three lines referral system.</div>
        <div>You will get:</div>
        <div>5% of your referral's winnings</div>
        <div>2.5% of the second line winnings</div>
        <div>2.5% of the third line winnings</div>
      </div>

      <Roadmap />

      <Events />
      <Tokenomics />
      {/*<Participants />*/}
      <Faq />
    </DefaultLayout>
  );
}
