import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const reAuthenticate = () => ({
  type: AT.REAUTHENTICATE,
});

export const logIn = (data) => ({
  type: AT.LOG_IN,
  payload: data,
});

export const setAuth = (data) => ({
  type: AT.SET_AUTH,
  payload: data,
});

export const logOut = () => ({
  type: AT.LOG_OUT,
});

export const setParent = (data) => ({
  type: AT.SET_PARENT,
  payload: data,
});
