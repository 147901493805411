import { default as bsc } from './bsc';
import { default as goerli } from './goerli';



//module.exports.dayTimeOffset = +0 * 60 * 60 * 1000; // 4*60*60*1000 = +4 часа

const isProduction = process?.env?.NODE_ENV === 'production' || true;

const regExpEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, 'g');
const regExpNum = new RegExp(/^[0-9]+$/, 'g');


const depositRequestDomain = {
  name: 'WMC.PRO Deposit Request',
};
const depositRequestTypes = {
  DepositRequest: [
    { name: 'id', type: 'string', },
    { name: 'guid', type: 'uint256', },
    { name: 'timestamp', type: 'uint256', },
    { name: 'chainId', type: 'uint256', },
    { name: 'wallet', type: 'address', },
    { name: 'nonce', type: 'uint256', },
    { name: 'refGuid', type: 'uint256', },
    { name: 'numSeasons', type: 'uint256', },
    { name: 'numDays', type: 'uint256', },
  ],
};

export default {
  isProduction,
  regExpEmail,
  regExpNum,
  depositRequestDomain,
  depositRequestTypes,

  ...bsc,
  ...goerli,
};
