import { useState, useContext, } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState, useModal } from 'react-simple-modal-provider';
import ModalBody from './ModalBody';
import Input from '@/components/common/Input';
import { default as C } from '@/constants';
import { toast } from 'react-toastify';
import ApiContext from '@/api/api-context';





export default function LoginModal({ children }) {
  const [isOpen, setOpen] = useModalState();

  const { i18n, t } = useTranslation();
  const api = useContext(ApiContext);

  const onClose = () => {
    setOpen(false);
    setEmail('');
  }

  const { open: openConfirmOtpCodeModal } = useModal('ConfirmOtpCodeModal');
  const [email, setEmail] = useState('');
  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value.trim().toLowerCase());
  }

  const [isProcessing, setProcessing] = useState(false);
  const onAction = async (e) => {
    e.preventDefault();
    if (isProcessing) return;
    if (email === '') return;
    //if (!C.regExpEmail.test(email)) {
    if (!email.includes('@')) {
      toast.warn('Incorrect email.');
      return;
    }

    setProcessing(true);
    try {
      const res = await api.sendLoginWebCode({ email, language: i18n.language, });
      if (res.ok) {
        openConfirmOtpCodeModal({ email, });
        onClose();
      } else {
        toast.error('Something went wrong.');
      }
    } catch (error) {
      toast.error('Something went wrong.');
      console.error(error);
    }
    setProcessing(false);
  }

  return (
    <Modal
      id={'LoginModal'}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      //asyncOpen={asyncOpen}
      draggable={false}
      duration={300}
      width={520}
      height={320}
    //radius={10}
    //backgroundColor={'rgb(254, 92, 80)'}
    //animation={modalAnimation.scaleUp}
    >
      <ModalBody
        isOpen={isOpen}
        title="Login"
        width="520"
        titleAlignment="left"
        actionName="Send code"
        action={onAction}
        closeName="Cancel"
        close={onClose}
        slot={<Input
          required={true}
          id='email' name='email' type='email'
          label="Email" placeholder="Enter email"
          pattern={C.regExpEmail}
          value={email}
          onChange={handleChangeEmail}
          readOnly={isProcessing}
        />}
      />
    </Modal>
  );
};
