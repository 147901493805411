import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const setBlockNumber = (data) => ({
  type: AT.SET_BLOCK_NUMBER,
  payload: data,
});

export const setChainId = (data) => ({
  type: AT.SET_CHAIN_ID,
  payload: data,
});
